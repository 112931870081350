import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  container: {
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
  },
  mainBox: {
    marginTop: "7rem",
    minHeight: "860px",
  },
  innerBox: {
    marginTop: "1rem",
  },
  wrapper: {
    borderRadius: "4px 4px",
    boxShadow: "2px 2px 4px 4px rgba(0, 0, 0, 0.16)",
    padding: "30px 30px 30px 30px !important",
  },
  confirmationButton: {
    width: "100%",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    fontSize: "1.25rem",
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  newAccountButton: {
    width: "100%",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    fontSize: "1.25rem",
    backgroundColor: theme.palette.ultimate.main,
    color: theme.palette.ultimate.contrastText,
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.palette.ultimate.main,
    },
  },
  linkForgotten: {
    textDecoration: "none",
  },
  textForgotten: {
    color: theme.palette.secondary.main,
  },
  divider: {
    height: "2px",
  },
  textNote: {
    color: theme.palette.primary.main,
    fontWeight: "normal",
    fontSize: "1.2rem",
  },
  errorMessage: {
    color: "red",
  },
  smallText: {
    textAlign: "center",
    color: theme.palette.darkGrey.main,
    fontSize: "0.6rem",
  },
}));
