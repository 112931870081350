import React from "react";

import Box from "@material-ui/core/Box";

import Layout from "components/Layout";

import HeroHome from "./partials/HeroHome";
import Solutions from "./partials/Solutions";

import { removeRecaptcha } from "config/utils";

const Home = () => {
  const listButtons = getListButtons();

  removeRecaptcha();

  return (
    <>
      <Layout listButtons={listButtons} appBarColor="primary">
        <HeroHome />
        <Layout.Content>
          <Box width="inherit" height="inherit" minHeight="1008px">
            <Solutions />
          </Box>
        </Layout.Content>
      </Layout>
    </>
  );
};

const getListButtons = () => {
  const list = [
    {
      label: "Goal",
      href: "/about",
      isActive: false,
      isNotActive: true,
    },
  ];

  if (
    localStorage.getItem("token") &&
    localStorage.getItem("token") !== "undefined" &&
    localStorage.getItem("userProviderId") &&
    localStorage.getItem("userProviderId") !== "-1"
  ) {
    list.push({
      label: "Edit solution provider",
      href: "/add-company",
      isButton: true,
      isActive: false,
      isNotActive: true,
    });
  } else {
    list.push({
      label: "Add solution provider",
      href: "/add-company-intro",
      isButton: true,
      isActive: false,
      isNotActive: true,
    });
  }
  return list;
};

export default Home;
