import React from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import { GET_PROVIDER } from "queries/providers";
import Modal from "components/Modal";
import { GAEvent, sortArrayByName, isValidUrlAllProtocols } from "config/utils";

const useStyles = makeStyles((theme) => ({
  white: {
    backgroundColor: theme.palette.secondary.contrastText,
  },
  divider: {
    height: "2px",
  },
  closeButton: {
    position: "absolute",
    top: "5px",
    right: "5px",
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  websiteButton: {
    width: "auto",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    fontSize: "1.25rem",
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));

const ProviderModal = ({ isOpen, onClose, providerId, solutionName }) => {
  const { push } = useHistory();
  const classes = useStyles();

  const { loading, data = {} } = useQuery(GET_PROVIDER, {
    variables: {
      id: providerId,
    },
    skip: !providerId,
  });

  if (loading) return null;

  const { provider } = data;
  const {
    name,
    description,
    main_office,
    url,
    url_social_media,
    size,
    solutions,
    countries,
    offices,
  } = provider;

  const sizeAdditionalText =
    size === "small"
      ? "(1-50 employees)"
      : size === "medium"
      ? "(50-250 employees)"
      : "(250+ employees)";

  const handleOnClickSolution = (e) => {
    GAEvent(`${name}`, "Visit solution", `Explore provider`);
    push(`/solution/${e.currentTarget.id}`);
  };

  const validURL = isValidUrlAllProtocols(url) ? url : `https:\\${url}`;
  console.log(url, validURL);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Header disableTypography>
        <Typography
          variant="h4"
          color="primary"
          style={{ fontWeight: "normal" }}
        >
          {name}
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          title="Close the dialog"
        >
          <CloseIcon />
        </IconButton>
      </Modal.Header>
      <Divider className={classes.divider} />
      <Modal.Content>
        <Grid container spacing={5}>
          <Grid item xs={12} lg={7}>
            <Box>
              <Typography variant="body1">{description}</Typography>
            </Box>
            {main_office && main_office.name && (
              <Box marginTop="2rem">
                <Typography gutterBottom variant="h6">
                  Main office:
                </Typography>
                <Typography variant="body1">{main_office.name}</Typography>
              </Box>
            )}
            {offices && offices.length > 0 && (
              <Box marginTop="2rem">
                <Typography gutterBottom variant="h6">
                  Additional offices:
                </Typography>
                {sortArrayByName(offices).map(({ name }) => (
                  <Typography variant="body1" key={name}>
                    {name}
                  </Typography>
                ))}
              </Box>
            )}
            {!!countries.length && (
              <Box marginTop="2rem">
                <Typography gutterBottom variant="h6">
                  Location of projects:
                </Typography>
                {sortArrayByName(countries).map(({ region }) => (
                  <Typography variant="body1" key={region}>
                    {region}
                  </Typography>
                ))}
              </Box>
            )}
            {!!size && (
              <Box marginTop="2rem">
                <Typography gutterBottom variant="h6">
                  Company size:
                </Typography>
                <Typography
                  variant="body1"
                  style={{ textTransform: "capitalize" }}
                >
                  {size + " " + sizeAdditionalText}
                </Typography>
              </Box>
            )}
            {!!url_social_media && (
              <Box marginTop="2rem">
                <Typography gutterBottom variant="h6">
                  Social media profile:
                </Typography>
                <a
                  href={url_social_media}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Typography variant="body1">{url_social_media}</Typography>
                </a>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} lg={5}>
            {provider.media && (
              <Box
                width={1}
                padding="1rem"
                className={classes.white}
                display="flex"
                justifyContent="center"
              >
                <img
                  alt={name}
                  src={provider.media.url}
                  style={{ width: "inherit", height: "inherit" }}
                />
              </Box>
            )}
            {!!solutions.length && (
              <Box marginTop="2rem">
                <Typography gutterBottom variant="h6">
                  Also working with:
                </Typography>
                {sortArrayByName(solutions).map(({ name, id }) => (
                  <Box
                    key={name}
                    className={classes.white}
                    marginY="1rem"
                    padding="1rem"
                  >
                    <Button
                      id={id}
                      onClick={handleOnClickSolution}
                      title={name}
                    >
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        style={{ fontWeight: "bold", textAlign: "left" }}
                      >
                        {name}
                      </Typography>
                    </Button>
                  </Box>
                ))}
              </Box>
            )}
          </Grid>
        </Grid>
      </Modal.Content>
      <Divider className={classes.divider} />
      <Modal.Footer>
        <Box width={1} height={1} display="flex" justifyContent="flex-end">
          <Button
            className={classes.websiteButton}
            target="_blank"
            rel="noopener noreferrer"
            href={validURL}
            title="Visit Provider website"
            onClick={() =>
              GAEvent(`${solutionName}`, "Visit provider site", `${size}`)
            }
          >
            Visit website
          </Button>
        </Box>
      </Modal.Footer>
    </Modal>
  );
};

export default ProviderModal;
