import React from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";

const Content = ({ children, withPadding = true }) => {
  return (
    <Container maxWidth="lg">
      <Box
        width="inherit"
        height="inherit"
        paddingBottom={withPadding ? "4rem" : ""}
        paddingTop={withPadding ? "2rem" : ""}
      >
        {children}
      </Box>
    </Container>
  );
};

export default Content;
