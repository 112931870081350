import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token && token !== "undefined" ? `Bearer ${token}` : "",
    },
  };
});
const cache = new InMemoryCache();
const httpLink = new HttpLink({
  uri: process.env.REACT_APP_BACKEND_URL_GRAPHQL,
});

const client = new ApolloClient({
  cache,
  link: authLink.concat(httpLink),
});

export default client;
