import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { RecoilRoot } from "recoil";
import ReactGA from "react-ga4";

import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider, responsiveFontSizes } from "@material-ui/core/styles";

import client from "config/apolloClient";
import { MikeTheme } from "theme/mike";

import ScrollToTop from "components/ScrollToTop";
import Solution from "containers/Solution";
import Home from "containers/Home";
import About from "containers/About";
import Partners from "containers/Partners";
import ContactUs from "containers/ContactUs";
import AddCompanyIntro from "containers/AddCompanyIntro";
import AddCompany from "containers/AddCompany/AddCompany";
import Login from "containers/Authentication/Login";
import RegisterUser from "containers/Authentication/RegisterUser";
import ForgottenPassword from "containers/Authentication/ForgottenPassword";
import ResetPassword from "containers/Authentication/ResetPassword";

import * as serviceWorker from "./serviceWorker";

// Init google analytics
const trackingId = "G-16YN8ZTC3Y";
ReactGA.initialize(trackingId);

ReactDOM.render(
  <ThemeProvider theme={responsiveFontSizes(MikeTheme)}>
    <CssBaseline />
    <ApolloProvider client={client}>
      <RecoilRoot>
        <Router>
          <ScrollToTop />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/about" component={About} />
            <Route path="/partners" component={Partners} />
            <Route path="/contact-us" component={ContactUs} />
            <Route path="/add-company-intro" component={AddCompanyIntro} />
            <Route path="/add-company" component={AddCompany} />
            <Route path="/solution/:solutionId" component={Solution} />
            <Route path="/login" component={Login} />
            <Route path="/register" component={RegisterUser} />
            <Route path="/forgotten-password" component={ForgottenPassword} />
            <Route path="/reset" component={ResetPassword} />
          </Switch>
        </Router>
      </RecoilRoot>
    </ApolloProvider>
  </ThemeProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
