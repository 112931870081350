import React from "react";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import { mikePalette } from "theme/mike";
import useStyles from "../../lib/useStyles";

const Step4 = ({ values, offices, countries, mobileView }) => {
  const classes = useStyles();

  const selectedOffice = offices.filter((item) =>
    values.step1.location.includes(item.id)
  );
  const selectedOffices = offices.filter((item) =>
    values.step1.otherLocations.includes(item.id)
  );

  const selectedCountries = countries.filter((item) =>
    values.step1.locationsByCompany.includes(item.id)
  );

  return values ? (
    <Box>
      <Typography
        variant="h4"
        color="primary"
        className={classes.subTitleReview}
      >
        Review the company profile
      </Typography>
      <Typography
        variant="h6"
        color="primary"
        className={classes.subTitleCentered}
      >
        Company description
      </Typography>
      <Box className={mobileView ? classes.fieldsMobile : classes.fields}>
        <TextField
          label="Company name"
          value={values.step1.companyName}
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          variant="filled"
        />
        <TextField
          label="Company description"
          value={values.step1.companyDescription}
          fullWidth
          margin="normal"
          multiline
          rowsMax={4}
          InputLabelProps={{
            shrink: true,
          }}
          variant="filled"
        />
        <TextField
          label="Link to company homepage"
          value={values.step1.linkToHomepage}
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          variant="filled"
        />
      </Box>
      {values.step1.linkToSocials && (
        <Box className={mobileView ? classes.fieldsMobile : classes.fields}>
          <TextField
            label="Link to your LinkedIn or other social media"
            value={values.step1.linkToSocials}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="filled"
          />
        </Box>
      )}
      <Typography
        variant="h6"
        color="primary"
        className={classes.subTitleCentered}
      >
        Location of the company and projects
      </Typography>
      <Box className={mobileView ? classes.fieldsMobile : classes.fields}>
        <TextField
          label="Location of main office"
          value={selectedOffice[0].name}
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          variant="filled"
        />
        {values.step1.otherLocations && values.step1.otherLocations.length > 0 && (
          <Box>
            <Typography
              variant="h6"
              color="primary"
              style={{
                marginTop: "16px",
                paddingLeft: "16px",
                fontSize: "0.8rem",
                fontWeight: "normal",
                color: mikePalette.darkGrey.main,
              }}
            >
              Location of additional offices
            </Typography>
            <List>
              {selectedOffices.map((office) => (
                <ListItem key={"office-" + office.id}>
                  <ListItemText primary={office.name} />
                </ListItem>
              ))}
            </List>
          </Box>
        )}
        <Typography
          variant="h6"
          color="primary"
          style={{
            marginTop: "16px",
            paddingLeft: "16px",
            fontSize: "0.8rem",
            fontWeight: "normal",
            color: mikePalette.darkGrey.main,
          }}
        >
          Location of projects and solutions
        </Typography>
        <List>
          {selectedCountries.map((country) => (
            <ListItem key={"country-" + country.id}>
              <ListItemText primary={country.name} />
            </ListItem>
          ))}
        </List>
        <TextField
          label="Size of company"
          value={values.step1.companySize}
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          variant="filled"
        />
      </Box>
      <Typography
        variant="h6"
        color="primary"
        className={classes.subTitleCentered}
      >
        Solutions that the company can provide
      </Typography>
      <Box className={mobileView ? classes.fieldsMobile : classes.fields}>
        <Typography
          variant="h6"
          color="primary"
          style={{
            marginTop: "16px",
            paddingLeft: "16px",
            fontSize: "0.8rem",
            fontWeight: "normal",
            color: mikePalette.darkGrey.main,
          }}
        >
          Solutions
        </Typography>
        <List>
          {values.step2.solutions.map((solution) => (
            <ListItem key={solution.id}>
              <ListItemText primary={solution.name} />
            </ListItem>
          ))}
        </List>
      </Box>
      <Typography
        variant="h6"
        color="primary"
        className={classes.subTitleCentered}
      >
        Logo
      </Typography>
      <Box
        padding="1rem"
        bgcolor="white"
        display="flex"
        justifyContent="center"
        className={classes.fields}
      >
        {values.step3.companyLogo && (
          <img
            alt="company's logo"
            src={values.step3.companyLogo.url}
            style={{ width: "auto", height: "inherit" }}
          />
        )}
      </Box>
      <Typography
        variant="h6"
        color="primary"
        className={classes.subTitleCentered}
      >
        Contact info for the person that UNEP-DHI can contact
      </Typography>
      <Box className={mobileView ? classes.fieldsMobile : classes.fields}>
        <TextField
          label="Name"
          value={localStorage.getItem("username")}
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          variant="filled"
        />
        <TextField
          label="E-mail address"
          value={localStorage.getItem("email")}
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          variant="filled"
        />
      </Box>
    </Box>
  ) : (
    <Box>
      <Typography variant="h4" color="error" className={classes.subTitleReview}>
        Please, insert your company's information
      </Typography>
    </Box>
  );
};

export default Step4;
