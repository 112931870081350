export const sendEmail = async (token, text) => {
  if (typeof window === "undefined") {
    return;
  }
  const subject = "Match! Water Solutions Portal";

  const body = { token: token, subject: subject, text: text };

  const options = {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/about`,
    options
  ).then((data) => {
    return data.response ? data.response : data;
  });
};
