import React, { useState, useEffect } from "react";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Chip from "@material-ui/core/Chip";

import { MikeTheme } from "theme/mike";
import useStyles from "./lib/useStyles";

const SelectChips = ({
  name,
  values,
  setFieldValue,
  required,
  multiple,
  options,
}) => {
  const classes = useStyles();
  const [selectedItems, setSelectedItems] = useState(values);
  const [canOpen, setCanOpen] = useState(false);

  useEffect(() => {
    setSelectedItems(values);
  }, [values]);

  const handleChange = (event) => {
    const itemValue = multiple ? event.target.value : [event.target.value];
    setSelectedItems(itemValue);
    setFieldValue(name, itemValue);
  };

  const handleDelete = (chipToDelete) => () => {
    const itemValue = multiple
      ? selectedItems.filter((chip) => chip !== chipToDelete)
      : "";
    setSelectedItems(itemValue);
    setFieldValue(name, itemValue);
  };

  const selectHandleOnOpen = () => {
    setCanOpen(!canOpen);
  };

  const getStyles = (item, selectedItem, theme) => {
    return {
      fontWeight:
        !selectedItem || selectedItem.indexOf(item) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
      backgroundColor:
        !selectedItem || selectedItem.indexOf(item) === -1
          ? theme.palette.primary.contrastText
          : theme.palette.darkGrey.light,
    };
  };

  const getLabel = (options, item) => {
    const optionFound = options.find((option) => option.id === item);
    return optionFound ? optionFound.name : "";
  };

  return (
    <>
      <Select
        name={name}
        value={selectedItems}
        onChange={handleChange}
        open={canOpen}
        onClick={selectHandleOnOpen}
        fullWidth
        required={required}
        multiple={multiple}
        renderValue={() => (
          <div className={classes.chips}>
            {options &&
              options.length > 0 &&
              selectedItems.map((item) => (
                <Chip
                  clickable={true}
                  key={item}
                  label={getLabel(options, item)}
                  className={classes.chip}
                  onDelete={handleDelete(item)}
                />
              ))}
          </div>
        )}
      >
        {options.map((option) => (
          <MenuItem
            key={option.id}
            value={option.id}
            style={getStyles(option.id, selectedItems, MikeTheme)}
          >
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default SelectChips;
