import React, { useState } from "react";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";

import useStyles from "./lib/useStyles";
import { ReactComponent as UploadLogo } from "assets/logoUpload.svg";

const ImageUpload = ({
  name,
  imageValue,
  setFieldValue,
  errors,
  getHelperText,
}) => {
  const classes = useStyles();

  const [imageFile, setImageFile] = useState(imageValue ? imageValue : "");
  const [uploading, setUploading] = useState(false);

  const fileInputRef = React.useRef(null);

  const handleClick = React.useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, []);

  const handleOnChange = (e) => {
    setUploading(true);
    const imageFile = e.target.files[0];
    const formData = new FormData();
    formData.append("files", imageFile);
    fetch(`${process.env.REACT_APP_BACKEND_URL}/upload`, {
      method: "POST",
      mode: "cors",
      body: formData,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then(async (response) => {
        const data = await response.json();
        setImageFile(data[0].url);
        setFieldValue(name, {
          id: data[0].id,
          url: data[0].url,
        });
        setUploading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      {uploading ? (
        <CircularProgress />
      ) : (
        <>
          <Box className={classes.buttons}>
            <Button
              component="div"
              className={classes.button}
              title="Upload logo"
              onClick={handleClick}
            >
              <div>
                <input
                  id="companyLogo"
                  type="file"
                  accept=".svg,.png,jpeg,jpg"
                  ref={fileInputRef}
                  onChange={handleOnChange}
                  className={classes.fileInput}
                />
                {imageFile ? (
                  <img
                    alt={imageFile}
                    src={imageFile}
                    className={classes.imagePreview}
                  />
                ) : (
                  <Typography variant="body1" className={classes.imageLabel}>
                    <UploadLogo className={classes.uploadLogo} />
                    Upload logo
                  </Typography>
                )}
              </div>
            </Button>
          </Box>
          <FormControl className={classes.formControl}>
            <FormHelperText
              className={
                errors["companyLogo"] ? classes.error : classes.helperText
              }
            >
              {getHelperText(errors, "companyLogo", "* = required")}
            </FormHelperText>
          </FormControl>
        </>
      )}
    </>
  );
};

export default ImageUpload;
