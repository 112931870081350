const fetchApi = async (url, data) => {
  const body = data ? data : {};

  const options = {
    method: "POST",
    mode: "cors",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  };
  const token = localStorage.getItem("token");
  if (token != null && token !== "" && token !== "undefined") {
    options.headers = {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
    };
  }
  return await fetch(url, options)
    .then(async (response) => {
      const data = await response.json();
      return { status: response.status, data };
    })
    .then((result) => {
      if (result.status < 200 || result.status >= 300) throw result;
      return result;
    });
};

const fetchApiPost = (url, data) => fetchApi(url, data);

export default fetchApiPost;
