import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  container: {
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
  },
  mainContent: {
    backgroundColor: theme.palette.primary.contrastText,
    width: "50%",
    paddingLeft: "4rem",
    paddingRight: "4rem",
  },
  confirmationButton: {
    width: "100%",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    fontSize: "1.25rem",
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  message: {
    minHeight: "6rem",
  },
  errorMessage: {
    color: "red",
  },
  smallText: {
    textAlign: "center",
    color: theme.palette.darkGrey.main,
    fontSize: "0.6rem",
  },
}));
