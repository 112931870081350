import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  paginationRoot: {
    padding: 0,
    minWidth: "0 !important",
    width: "1rem !important",
    height: "1.75rem !important",
    "&:hover,&:focus": {
      backgroundColor: "transparent !important",
    },
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
  },
  paginationSelected: {
    backgroundColor: "transparent !important",
    borderRadius: 0,
    borderBottom: `2px solid ${theme.palette.text.primary}`,
  },
}));
