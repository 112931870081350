import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";

import Linkify from "react-linkify";

import useStyles from "./lib/useStyles";

const addLineBreaks = (string) =>
  string.split("\n").map((text, index) => (
    <React.Fragment key={`${text}-${index}`}>
      {text}
      <br />
    </React.Fragment>
  ));

const AlertDialog = ({
  open,
  close,
  handleResponse,
  title,
  message,
  maxWidth,
  cancelButton,
}) => {
  const handleClose = () => {
    close();
  };

  const confirm = (response) => {
    if (handleResponse) {
      handleResponse(response);
    }
    close();
  };

  const classes = useStyles();

  const componentDecorator = (href, text, key) => (
    <a href={href} key={key} target="_blank" rel="noopener noreferrer">
      {text}
    </a>
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{
        paper: classes.paper,
      }}
      maxWidth={maxWidth}
    >
      <DialogTitle>
        <Box>
          <Typography variant="h4" className={classes.titleText}>
            {title}
          </Typography>
        </Box>
      </DialogTitle>
      <Divider className={classes.divider} />
      <DialogContent className={classes.dialogContent}>
        <DialogContentText className={classes.messageText}>
          <Linkify componentDecorator={componentDecorator}>
            {addLineBreaks(message)}
          </Linkify>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {cancelButton ? (
          <Button
            className={classes.cancelButton}
            onClick={() => confirm(false)}
            title="No"
            color="primary"
          >
            No
          </Button>
        ) : (
          ""
        )}
        <Button
          className={classes.confirmationButton}
          onClick={() => confirm(true)}
          title="OK"
          color="primary"
          autoFocus
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
