import fetchApiPost from "queries/fetchApi";

//register a new user
export const registerUser = (username, email, password, token) => {
  if (typeof window === "undefined") {
    return;
  }
  return new Promise((resolve, reject) => {
    fetchApiPost(`${process.env.REACT_APP_BACKEND_URL}/auth/local/register`, {
      username,
      email,
      password,
      token,
    })
      .then((res) => {
        localStorage.setItem("token", res.data.jwt);
        localStorage.removeItem("userProviderId");
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const login = (identifier, password) => {
  //prevent function from being ran on the server
  if (typeof window === "undefined") {
    return;
  }

  return new Promise((resolve, reject) => {
    fetchApiPost(`${process.env.REACT_APP_BACKEND_URL}/auth/local/`, {
      identifier,
      password,
    })
      .then((res) => {
        localStorage.removeItem("logout");
        localStorage.setItem("token", res.data.jwt);
        localStorage.setItem("username", res.data.user.username);
        localStorage.setItem("userId", res.data.user.id);
        localStorage.setItem("email", res.data.user.email);
        if (res.data.user.providers) {
          localStorage.setItem("userProviderId", res.data.user.providers.id);
        }
        resolve(res);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const logout = () => {
  localStorage.clear();
  localStorage.setItem("logout", Date.now());
};

export const forgotPassword = (email, token) => {
  return new Promise((resolve, reject) => {
    fetchApiPost(`${process.env.REACT_APP_BACKEND_URL}/auth/forgot-password/`, {
      email,
      token,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const resetPassword = (password, passwordConfirmation, code) => {
  return new Promise((resolve, reject) => {
    fetchApiPost(`${process.env.REACT_APP_BACKEND_URL}/auth/reset-password/`, {
      code,
      password,
      passwordConfirmation,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
