import React, { useEffect } from "react";

import Pagination from "@material-ui/lab/Pagination";
import PaginationItem from "@material-ui/lab/PaginationItem";

import useStyles from "./lib/useStyles";

const MyPagination = ({ counter, limit, page, handleOnChange }) => {
  const classes = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);
  return (
    <Pagination
      count={Math.ceil(counter / limit)}
      page={page}
      onChange={handleOnChange}
      renderItem={(item) => (
        <PaginationItem
          shape="rounded"
          classes={{
            root: classes.paginationRoot,
            selected: classes.paginationSelected,
          }}
          {...item}
        />
      )}
    />
  );
};

export default MyPagination;
