import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";

import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

import { ReactComponent as EcoLogo } from "assets/iconEco.svg";
import { GET_PROVIDERS_BY_SOLUTION_COUNT } from "queries/counts";
import { GAEvent } from "config/utils";

import useStyles from "./lib/useStyles";


const SolutionCard = ({ name, challenges, id, media = {}, greenSolution }) => {
  const classes = useStyles();

  const { url, formats } = media ? media : {};
  const { small } = formats || {};
  const { url: urlSmall } = small || {};
  const useURL = urlSmall || url;


  const isGreen = greenSolution ? greenSolution : !!greenSolution;

  const { data = {}, loading } = useQuery(GET_PROVIDERS_BY_SOLUTION_COUNT, {
    variables: { where: { solutions: id } },
  });
  const { providersConnection = { aggregate: { count: 0 } } } = data;

  const withChallenges = challenges && !!challenges.length;

  if (!withChallenges) return null;

  if (loading) return null;

  return (
    <Card className={classes.root}>
      <CardActionArea
        to={`/solution/${id}`}
        component={Link}
        onClick={() => GAEvent(`${name}`, "Visit solution", `Explore provider`)}
      >
        {isGreen && (
          <EcoLogo
            style={{ position: "absolute", right: "10px", top: "10px" }}
          />
        )}
        <CardMedia
          component="img"
          alt="logo"
          height="200rem"
          image={useURL}
        />
        <CardContent>
          <Box
            height="5rem"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Typography
              gutterBottom
              variant="h6"
              component="h2"
              noWrap
              title={name}
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "inherit",
              }}
            >
              {name}
            </Typography>
            <Box display="flex" justifyContent="space-between">
              <Box>
                <Typography variant="body2" color="textSecondary" component="p">
                  Challenges
                </Typography>
                <Typography
                  variant="body2"
                  color="primary"
                  component="p"
                  noWrap
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "inherit",
                  }}
                >
                  {challenges &&
                    challenges.map((x) => x.displayName).join(", ")}
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2" color="textSecondary" component="p">
                  Providers
                </Typography>
                <Typography variant="body2" color="primary" component="p">
                  {providersConnection.aggregate.count}
                </Typography>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default SolutionCard;
