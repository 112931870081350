import React, { useEffect, useState } from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

const Select = ({
  options,
  defaultValue,
  defaultText,
  onChange,
  icon: Icon,
  ...rest
}) => {
  const [value, setValue] = useState(defaultValue);

  const handleChange = ({ target: { value: newValue } }) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (defaultValue !== value) onChange(value);
  }, [defaultValue, onChange, value]);

  return (
    <TextField
      select
      variant="filled"
      value={value}
      onChange={handleChange}
      InputProps={{
        startAdornment: (
          <InputAdornment>
            <Icon />
          </InputAdornment>
        ),
      }}
      {...rest}
    >
      <MenuItem value="all">{defaultText}</MenuItem>
      {options.map(({ label, value }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default Select;
