import React, { useState } from "react";

import { Redirect, Link } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";

import Layout from "components/Layout";

import { login } from "queries/auth";
import { removeRecaptcha } from "config/utils";

import useStyles from "../lib/useStyles";

import { listButtons } from "../utilsAuthentication";

const Login = () => {
  const classes = useStyles();
  const [data, updateData] = useState({ username: "", password: "" });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});

  const onChange = (event) => {
    updateData({ ...data, [event.target.name]: event.target.value });
  };

  const submit = (e) => {
    e.preventDefault();
    setLoading(true);
    setError({});

    login(data.username, data.password)
      .then((res) => {
        setLoading(false);
      })
      .catch((error) => {
        error.data ? setError(error.data) : setError(error);
        setLoading(false);
      });
  };

  removeRecaptcha();

  if (
    !loading &&
    localStorage.getItem("token") &&
    localStorage.getItem("token") !== "undefined"
  ) {
    return <Redirect to="/add-company" />;
  } else {
    return (
      <>
        <Layout listButtons={listButtons} appBarColor="transparent">
          <Layout.Content>
            <Box className={classes.mainBox}>
              <Container maxWidth="lg" className={classes.container}>
                <Box className={classes.innerBox}>
                  <section className={classes.wrapper}>
                    <Typography
                      variant="h4"
                      color="primary"
                      style={{ fontWeight: "normal" }}
                    >
                      Log in
                    </Typography>
                    <Box marginTop="2rem" marginBottom="2rem">
                      <Divider className={classes.divider} />
                    </Box>
                    {Object.entries(error).length !== 0 &&
                      error.constructor === Object &&
                      error.message.map((error) => {
                        return (
                          <div
                            key={error.messages[0].id}
                            style={{ marginBottom: 10 }}
                          >
                            <small style={{ color: "red" }}>
                              {error.messages[0].message}
                            </small>
                          </div>
                        );
                      })}
                    <Box marginTop="2rem">
                      <TextField
                        name="username"
                        label="User name or email"
                        fullWidth
                        required
                        type="text"
                        onChange={(event) => onChange(event)}
                        value={data.username}
                      />
                      <TextField
                        name="password"
                        label="Password"
                        fullWidth
                        required
                        type="password"
                        onChange={(event) => onChange(event)}
                        value={data.password}
                      />
                    </Box>
                    <Box marginTop="2rem">
                      <Button
                        className={classes.confirmationButton}
                        onClick={submit}
                        title="Log in"
                        disabled={loading}
                      >
                        Log in
                      </Button>
                    </Box>
                    <Box marginTop="2rem">
                      <Link
                        className={classes.linkForgotten}
                        to={"/forgotten-password"}
                      >
                        <Typography
                          variant="body1"
                          className={classes.textForgotten}
                        >
                          Forgotten Password?
                        </Typography>
                      </Link>
                    </Box>
                    <Box marginTop="2rem">
                      <Divider className={classes.divider} />
                    </Box>
                    <Box marginTop="2rem">
                      <Button
                        component={Link}
                        to="/register"
                        className={classes.newAccountButton}
                        title="Create new account"
                      >
                        New account
                      </Button>
                    </Box>
                  </section>
                  <Box marginTop="4rem">
                    <Typography variant="body1" className={classes.textNote}>
                      Add a solution provider so business can find you
                    </Typography>
                  </Box>
                </Box>
              </Container>
            </Box>
          </Layout.Content>
        </Layout>
      </>
    );
  }
};

export default Login;
