import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(() => ({
  mainContent: {
    marginLeft: "3.5rem",
    marginRight: "3.5rem",
  },
  mainContentMobile: {
    marginLeft: "1rem",
    marginRight: "1rem",
  },
  title: {
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  text: {
    marginBottom: "1rem",
    fontWeight: "normal",
    lineHeight: 1.25,
  },
}));
