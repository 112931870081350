import React, { useEffect, useState, useCallback } from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";

import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  input: {
    "&::placeholder": {
      color: theme.palette.primary.main,
      opacity: 1,
    },
  },
}));

const Field = ({ options, defaultValue, onChange, icon: Icon, ...rest }) => {
  const classes = useStyles();
  const [value, setValue] = useState(defaultValue);

  const handleChange = ({ target: { value: newValue } }) => {
    setValue(newValue);
  };

  const handleClearText = useCallback((e) => {
    setValue("");
  }, []);

  useEffect(() => {
    if (defaultValue !== value) onChange(value);
  }, [defaultValue, onChange, value]);

  return (
    <TextField
      variant="filled"
      value={value}
      onChange={handleChange}
      InputProps={{
        classes: { input: classes.input },
        startAdornment: (
          <InputAdornment>
            <Icon style={{ alignSelf: "normal" }} />
          </InputAdornment>
        ),
        endAdornment:
          value !== "" ? (
            <InputAdornment position="end">
              <IconButton aria-label="clear" onClick={handleClearText}>
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          ) : null,
      }}
      {...rest}
    />
  );
};

export default Field;
