import React from "react";
import { useQuery } from "@apollo/client";

import CircularProgress from "@material-ui/core/CircularProgress";

const Query = ({
  children,
  query,
  start,
  limit = 9,
  challenge_name,
  country_id,
  name_contains,
  provider_name_contains,
}) => {
  const { data, loading, error } = useQuery(query, {
    variables: {
      start: start,
      limit: limit,
      challenge_name,
      country_id,
      name_contains,
      provider_name_contains,
    },
  });

  if (loading) return <CircularProgress />;
  if (error) return <p>Error: {JSON.stringify(error)}</p>;
  return children({ data });
};

export default Query;
