/**
 * The colors from DHI Official Guidelines
 */
export const MIKE_COLORS = {
    BRANDBLUE_DEFAULT: '#0B4566',
    BRANDBLUE_DARK: '#09334B',
    BRANDBLUE_LIGHT: '#93C4D4',

    ACTIONBLUE_DEFAULT: '#00A4EC',
    ACTIONBLUE_DARK: '#008BEC',
    ACTIONBLUE_LIGHT: '#97DBF9',

    GREEN_DEFAULT: '#61C051',
    GREEN_DARK: '#3EB22A',
    GREEN_LIGHT: '#BFE7B7',

    PINK_DEFAULT: '#FD3F75',
    PINK_DARK: '#D40D57',
    PINK_LIGHT: '#FFB1C8',

    DARKGREY_DEFAULT: '#86A2B3',
    DARKGREY_DARK: '#557A8F',
    DARKGREY_LIGHT: '#CFDBE2',

    MEDIUMGREY_DEFAULT: '#DBE4E9',
    MEDIUMGREY_DARK: '#CFDBE2',
    MEDIUMGREY_LIGHT: '#F2F5F7',

    XLIGHTGREY: '#F8F8F8',

    WHITE: '#FFFFFF',
    BLACK: '#000000',
};

/**
 * The map colors from DHI Official Guidelines
 */
export const MIKE_MAP_COLORS = {
    SKYBLUE: '#0EE1FE',
    DUSTYROSE: '#CF6766',
    TURQUISE: '#00FFE1',
    PLUM: '#8E074D',
    VIOLET: '#D779FF',
    CORAL: '#CF6766',
};

export default MIKE_COLORS;
