import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import { validateURL, isValidUrlAllProtocols } from "config/utils";

// initialization of formValues for adding a new company
export const setDefaultValuesForEachStep = () => {
  const values = {
    step1: {
      companyName: "",
      companyDescription: "",
      linkToHomepage: "",
      linkToSocials: "",
      location: [],
      otherLocations: [],
      locationsByCompany: [],
      companySize: "",
    },
    step2: {
      solutions: [],
    },
    step3: {
      companyLogo: {},
    },
  };
  return values;
};

// initialization of formvalues for existing company editing
export const setCompanyValuesForEachStep = (company, setLoadedCompany) => {
  const values = {
    step1: {
      companyName: company.name,
      companyDescription: company.description,
      linkToHomepage: company.url || "",
      linkToSocials: company.url_social_media || "",
      location: company.main_office ? [company.main_office.id + ""] : [""],
      otherLocations: company.offices
        ? company.offices.map((item) => item.id + "")
        : [],
      locationsByCompany: company.countries
        ? company.countries.map((item) => item.id + "")
        : [],
      companySize: company.size,
    },
    step2: {
      solutions: company.solutions ? company.solutions : [],
    },
    step3: {
      companyLogo: company.media,
    },
  };
  setLoadedCompany(true);
  return values;
};

export const validateStep = (num, allValues) => {
  let errors = [];

  switch (num) {
    case 1:
      const values1 = allValues["step1"];

      const errors1 = {
        companyName: "",
        companyDescription: "",
        linkToHomepage: "",
        linkToSocials: "",
        location: "",
        otherLocations: "",
        locationsByCompany: "",
        companySize: "",
      };

      if (!values1.companyName || !values1.companyName.trim()) {
        errors1.companyName = "The company name is required";
        errors.push(errors1);
      } else {
        if (values1.companyName && values1.companyName.trim().length > 40) {
          errors1.companyName = "The company name must be 40 character or less";
          errors.push(errors1);
        }
      }
      if (!values1.companyDescription || !values1.companyDescription.trim()) {
        errors1.companyDescription = "The company description is required";
        errors.push(errors1);
      } else {
        if (
          values1.companyDescription &&
          values1.companyDescription.trim().length > 1000
        ) {
          errors1.companyDescription =
            "The company description must be 1000 character or less";
          errors.push(errors1);
        }
      }
      if (!values1.linkToHomepage || !values1.linkToHomepage.trim()) {
        errors1.linkToHomepage = "The link to Homepage is required";
        errors.push(errors1);
      } else {
        if (!isValidUrlAllProtocols(values1.linkToHomepage)) {
          errors1.linkToHomepage =
            "The link to Homepage must be a valid URL such as https://www.domain.com or http://www.domain.com";
          errors.push(errors1);
        }
      }
      if (
        !values1.linkToSocials &&
        values1.linkToSocials.trim() &&
        !validateURL(values1.linkToSocials)
      ) {
        errors1.linkToSocials =
          "The link to a social media must be a valid URL";
        errors.push(errors1);
      }
      if (
        !values1.location ||
        (values1.location && values1.location.length === 0)
      ) {
        errors1.location = "The main office location is required";
        errors.push(errors1);
      }
      if (
        !values1.locationsByCompany ||
        (values1.locationsByCompany && values1.locationsByCompany.length === 0)
      ) {
        errors1.locationsByCompany =
          "At least one location of projects done by the company is required";
        errors.push(errors1);
      }
      if (!values1.companySize) {
        errors1.companySize = "The company size is required";
        errors.push(errors1);
      }
      break;

    case 2:
      const values2 = allValues["step2"];

      const errors2 = {
        solutions: "",
      };

      if (values2.solutions && values2.solutions.length === 0) {
        errors2.solutions = "At least one solution is required";
        errors.push(errors2);
      }
      break;

    case 3:
      const values3 = allValues["step3"];

      const errors3 = {
        companyLogo: "",
      };

      if (!values3.companyLogo || !values3.companyLogo.url) {
        errors3.companyLogo = "The logo is required";
        errors.push(errors3);
      }
      break;

    default:
      break;
  }

  return errors.length > 0 ? errors[0] : errors;
};

export const steps = [
  {
    title: "Introduce company",
    component: Step1,
  },
  {
    title: "Select solutions",
    component: Step2,
  },
  {
    title: "Add logo and contact info",
    component: Step3,
  },
  {
    title: "Confirm!",
    component: Step4,
  },
];

export const handleGoNext = (activeStep, setActiveStep) => {
  setActiveStep(activeStep + 1);
  window.scrollTo(0, 0);
};

export const handleGoBack = (activeStep, setActiveStep) => {
  setActiveStep(activeStep - 1);
  window.scrollTo(0, 0);
};

export const getListButtons = (loadedCompany) => {
  const list = [
    {
      label: "Goal",
      href: "/about",
      isActive: false,
      isNotActive: true,
    },
  ];

  if (
    localStorage.getItem("userProviderId") &&
    localStorage.getItem("userProviderId") !== "-1" &&
    loadedCompany
  ) {
    list.push({
      label: "Edit solution provider",
      href: "/add-company",
      isButton: true,
      isActive: true,
      isNotActive: false,
    });
  } else {
    list.push({
      label: "Add solution provider",
      href: "/add-company-intro",
      isButton: true,
      isActive: true,
      isNotActive: false,
    });
  }
  return list;
};
