import React from "react";
import clsx from "clsx";

import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";

const UserAvatar = ({ setOpenAlert, classes, appBarColor }) => {
  return (
    <Button
      color="primary"
      onClick={() => setOpenAlert(true)}
      title={localStorage.getItem("email")}
      className={classes.button}
    >
      <Avatar
        className={clsx(
          classes.button,
          {
            [classes.labelWhite]: appBarColor === "primary",
          },
          {
            [classes.labelBlue]: appBarColor !== "primary",
          },
          {
            [classes.buttonWhite]: appBarColor === "primary",
          },
          {
            [classes.buttonBlue]: appBarColor !== "primary",
          },
          {
            [classes.labelWhiteIsNotActive]: appBarColor === "primary",
          },
          {
            [classes.labelBlueIsNotActive]: appBarColor !== "primary",
          },
          {
            [classes.buttonWhiteIsNotActive]: appBarColor === "primary",
          },
          {
            [classes.buttonBlueIsNotActive]: appBarColor !== "primary",
          }
        )}
      >
        {localStorage.getItem("username")
          ? localStorage.getItem("username").charAt(0).toUpperCase()
          : localStorage.getItem("email")
          ? localStorage.getItem("email").charAt(0).toUpperCase()
          : ""}
      </Avatar>
    </Button>
  );
};

export default UserAvatar;
