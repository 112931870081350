import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import clsx from "clsx";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import UserAvatar from "components/NavButtons/UserAvatar";

import AlertDialog from "components/AlertDialog";
import { logout } from "../../queries/auth";

import useStyles from "./lib/useStyles";
import SimpleMenu from "./SimpleMenu";

const NavButtons = ({ list, appBarColor }) => {
  const classes = useStyles({});
  const history = useHistory();
  const [openAlert, setOpenAlert] = useState(false);

  const handleResponse = (answer) => {
    if (answer) {
      logout();
      history.push("/");
    }
  };

  const menuItemsList = [
    {
      label: "Goal",
      href: "/about",
    },
    {
      label: "Partners",
      href: "/partners",
    },
    {
      label: "Contact us",
      href: "/contact-us",
    },
  ];

  return (
    <>
      {list.map(
        ({
          label,
          href,
          isActive = false,
          isButton = false,
          isNotActive = true,
        }) =>
          // button for add solution provider
          isButton ? (
            <Link key={label} className={classes.link} to={href}>
              <Button
                variant="contained"
                className={clsx(
                  classes.button,
                  {
                    [classes.buttonWhite]: appBarColor === "primary",
                  },
                  {
                    [classes.buttonWhiteIsNotActive]:
                      appBarColor === "primary" && isNotActive,
                  },
                  {
                    [classes.buttonBlue]: appBarColor !== "primary",
                  },
                  {
                    [classes.buttonBlueIsNotActive]:
                      appBarColor !== "primary" && isNotActive,
                  }
                )}
              >
                <Typography
                  variant="body2"
                  className={clsx(
                    {
                      [classes.labelWhite]: appBarColor === "primary",
                    },
                    {
                      [classes.labelBlue]: appBarColor !== "primary",
                    },
                    {
                      [classes.labelIsActive]: isActive,
                    },
                    {
                      [classes.labelWhiteIsNotActive]:
                        appBarColor === "primary" && isNotActive,
                    },
                    {
                      [classes.labelBlueIsNotActive]:
                        appBarColor !== "primary" && isNotActive,
                    }
                  )}
                >
                  {label}
                </Typography>
              </Button>
            </Link>
          ) : (
            // menu for sub-pages About, Partners, Contact us
            <SimpleMenu
              key={label}
              appBarColor={appBarColor}
              isActive={isActive}
              isNotActive={isNotActive}
              menuItems={menuItemsList}
            />
          )
      )}
      {/* user's avatar button */}
      {localStorage.getItem("token") &&
      localStorage.getItem("token") !== "undefined" ? (
        <UserAvatar
          setOpenAlert={setOpenAlert}
          classes={classes}
          appBarColor={appBarColor}
        />
      ) : (
        ""
      )}
      {openAlert && (
        <AlertDialog
          open={openAlert}
          close={() => setOpenAlert(false)}
          handleResponse={(response) => handleResponse(response)}
          title={"Log out"}
          message={"Do you want to log out?"}
          maxWidth="sm"
          cancelButton
        />
      )}
    </>
  );
};

export default NavButtons;
