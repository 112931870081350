import React, { useState, useReducer } from "react";
import { useQuery } from "@apollo/client";
import clsx from "clsx";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import Pagination from "components/Pagination";
import Layout from "components/Layout";
import Range from "components/Range";
import ProviderCard from "components/ProviderCard";
import AlertDialog from "components/AlertDialog";

import SDGs from "./partials/SDGs";
import Challenges from "./partials/Challenges";
import useStyles from "./lib/useStyles";

import { removeRecaptcha } from "config/utils";
import { GET_SOLUTION } from "queries/solutions";
import { GET_PROVIDERS_BY_SOLUTION } from "queries/providers";
import { GET_PROVIDERS_BY_SOLUTION_COUNT } from "queries/counts";

import { ReactComponent as EcoLogo } from "assets/iconEco.svg";

import reducer from "./lib/reducer";
import initialState from "./lib/initialState";

const Solution = ({ match }) => {
  const {
    params: { solutionId },
  } = match;

  const classes = useStyles();

  const limit = 8;

  const { data = {}, loadingProvidersCounter } = useQuery(
    GET_PROVIDERS_BY_SOLUTION_COUNT,
    {
      variables: { where: { solutions: solutionId } },
    }
  );
  const { providersConnection = { aggregate: { count: 0 } } } = data;
  const providersCounter = providersConnection.aggregate.count;

  const [state, dispatch] = useReducer(reducer, initialState);

  const { data: dataProviders = {}, loadingProviders } = useQuery(
    GET_PROVIDERS_BY_SOLUTION,
    {
      variables: { ...state, limit: limit, where: { solutions: solutionId } },
    }
  );
  const { providers = [] } = dataProviders;

  const handleOnChange = (event, value) => {
    dispatch({ payload: value, limit: limit });
  };

  const { data: dataSolutions = {}, loading } = useQuery(GET_SOLUTION, {
    variables: { where: { id: solutionId } },
  });

  const { solutions = [] } = dataSolutions;
  const solution = solutions[0] || {};

  const {
    name: solutionName = "",
    description = "",
    description_short = "",
    media = {},
    sdgs = [],
    challenges = [],
    cost_min = 0,
    cost_max = 0,
    investment_min = 0,
    investment_max = 0,
    maturity_min = 0,
    maturity_max = 0,
    time_min = 0,
    time_max = 0,
    green_solution = false,
  } = solution;

  const isGreen = green_solution ? green_solution : !!green_solution;

  const { url, formats } = media ? media : {};
  const { medium } = formats || {};
  const { url: urlMedium } = medium || {};
  const useURL = urlMedium || url;

  const [openAlertDescription, setOpenAlertDescription] = useState(false);
  const [openAlertImplementation, setOpenAlertImplementation] = useState(false);

  let implementationText =
    "This Match! Portal includes a general assessment of four dimensions relating to implementation of the technology.\n";
  implementationText +=
    "It represents an indicative assessment scale of 1-5 as follows:\n";
  implementationText +=
    "\u2022 Technological maturity: 1 - in early stages of research and development, to 5 – fully mature and widely used\n";
  implementationText +=
    "\u2022 Initial investment: 1 – very low cost, to 5 – very high cost investment needed to implement technology\n";
  implementationText +=
    "\u2022 Operational costs: 1 – very low/no cost, to 5 – very high costs of operation and maintenance\n";
  implementationText +=
    "\u2022 Implementation timeframe: 1 – very quick to implement and reach desired capacity, to 5 – significant time investments needed to establish and/or reach full capacity\n\n";
  implementationText +=
    "This assessment is to be used as an indication only and is to be seen as relative to the other technologies included in this portal.\n";
  implementationText +=
    "More specific costs and timelines are to be identified as relevant for the specific technology and geography.";

  const listButtons = getListButtons();

  removeRecaptcha();

  return (
    <>
      <Layout listButtons={listButtons} appBarColor="transparent">
        <Layout.Content>
          <Box marginTop="5rem" minHeight="962px">
            <Container maxWidth="lg">
              {loading ? (
                <div className={classes.loadingImageContainer}>
                  <CircularProgress />
                </div>
              ) : (
                <Grid container spacing={10}>
                  <Grid item xs={12} lg={4}>
                    <Box display="flex" alignItems="center" overflow="hidden">
                      <Typography gutterBottom variant="h4">
                        {solutionName}
                      </Typography>
                      <Button
                        color="primary"
                        className={classes.alertButton}
                        onClick={() => setOpenAlertDescription(true)}
                        title={"Display long description"}
                      >
                        <InfoOutlinedIcon />
                      </Button>
                    </Box>
                    <Typography variant="body1">{description_short}</Typography>

                    <Box marginTop="3rem">
                      <Box
                        display="flex"
                        alignItems="center"
                        marginBottom="1.5rem"
                      >
                        <Typography variant="h6" color="textSecondary">
                          Implementation Considerations
                        </Typography>
                        <Button
                          className={clsx(
                            classes.alertButton,
                            classes.alertButtonSecondary
                          )}
                          onClick={() => setOpenAlertImplementation(true)}
                          title={"Display implementation considerations"}
                        >
                          <InfoOutlinedIcon />
                        </Button>
                      </Box>
                      <Box marginBottom="3rem">
                        <Range
                          title="Tech maturity"
                          list={[
                            "Basic",
                            "Tested",
                            "Proof of concept",
                            "Validated",
                            "Mature",
                          ]}
                          min={maturity_min}
                          max={maturity_max}
                        />
                      </Box>

                      <Box marginBottom="2rem">
                        <Range
                          title="Initial investment"
                          list={["Small", "", "Medium", "", "Large"]}
                          min={investment_min}
                          max={investment_max}
                        />
                      </Box>

                      <Box marginBottom="2rem">
                        <Range
                          title="Operational costs"
                          list={["Low", "", "Medium", "", "Large"]}
                          min={cost_min}
                          max={cost_max}
                        />
                      </Box>

                      <Box marginBottom="2rem">
                        <Range
                          title="Implementation timeframe"
                          list={["Short", "", "Medium", "", "Long"]}
                          min={time_min}
                          max={time_max}
                        />
                      </Box>
                    </Box>

                    <SDGs sdgs={sdgs} />

                    <Challenges challenges={challenges} />
                  </Grid>
                  <Grid item xs={12} lg={8}>
                    {url && (
                      <Box width={1} className={classes.imageBox}>
                        <img
                          src={useURL}
                          alt={solutionName}
                          title={solutionName}
                        />
                        {isGreen && (
                          <EcoLogo
                            style={{
                              position: "absolute",
                              right: "10px",
                              top: "10px",
                            }}
                          />
                        )}
                      </Box>
                    )}
                    {loadingProviders || loadingProvidersCounter ? (
                      <div className={classes.loadingImageContainer}>
                        <CircularProgress />
                      </div>
                    ) : (
                      <>
                        <Grid
                          container
                          spacing={5}
                          className={classes.providersList}
                        >
                          {providers.map(
                            ({
                              providerId,
                              name,
                              media,
                              size,
                              solutions,
                              countries,
                            }) => (
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}
                                key={providerId}
                              >
                                <ProviderCard
                                  solutionId={solutionId}
                                  providerId={providerId}
                                  name={name}
                                  media={media ? media : {}}
                                  size={size}
                                  solutions={solutions}
                                  countries={countries}
                                  solutionName={solutionName}
                                />
                              </Grid>
                            )
                          )}
                        </Grid>
                        <Box
                          display="flex"
                          justifyContent="center"
                          marginTop="2rem"
                          marginBottom="-2rem"
                        >
                          {providers && providersCounter > 0 ? (
                            <Pagination
                              counter={providersCounter}
                              limit={limit}
                              page={state.page}
                              handleOnChange={handleOnChange}
                            />
                          ) : (
                            ""
                          )}
                        </Box>
                      </>
                    )}
                  </Grid>
                </Grid>
              )}
            </Container>
          </Box>
        </Layout.Content>
      </Layout>
      {openAlertDescription && (
        <AlertDialog
          open={openAlertDescription}
          close={() => setOpenAlertDescription(false)}
          title={"About " + solutionName}
          message={description}
          maxWidth="sm"
        />
      )}
      {openAlertImplementation && (
        <AlertDialog
          open={openAlertImplementation}
          close={() => setOpenAlertImplementation(false)}
          title={"About Implementation Considerations"}
          message={implementationText}
          maxWidth="lg"
        />
      )}
    </>
  );
};

const getListButtons = () => {
  const list = [
    {
      label: "Goal",
      href: "/about",
      isActive: false,
      isNotActive: true,
    },
  ];

  if (
    localStorage.getItem("userProviderId") &&
    localStorage.getItem("userProviderId") !== "-1"
  ) {
    list.push({
      label: "Edit solution provider",
      href: "/add-company",
      isButton: true,
      isActive: false,
      isNotActive: true,
    });
  } else {
    list.push({
      label: "Add solution provider",
      href: "/add-company-intro",
      isButton: true,
      isActive: false,
      isNotActive: true,
    });
  }
  return list;
};

export default Solution;
