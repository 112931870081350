const _whereClause = (filter) => {
  let filterQuery = {};
  if (filter.challenge === "all") {
  } else {
    filterQuery.challenge_name = filter.challenge;
  }
  if (filter.location !== "all") {
    filterQuery.country_id = parseInt(filter.location);
  }
  if (filter.techSolution !== "") {
    filterQuery.name_contains = filter.techSolution;
  }
  if (filter.provider !== "") {
    filterQuery.provider_name_contains = filter.provider;
  }
  return filterQuery;
};

const reducer = (state, action) => {
  const { type, payload, limit } = action;
  switch (type) {
    case "page":
      return { ...state, page: payload, start: (payload - 1) * limit };
    case "filter": {
      return { page: 1, start: 0, ..._whereClause(payload) };
    }
    default:
      throw new Error();
  }
};

export default reducer;
