import { gql } from "@apollo/client";

export const GET_PROVIDER = gql`
  query provider($id: ID!) {
    provider(id: $id) {
      name
      description
      url
      url_social_media
      size
      countries {
        id
        region
      }
      offices {
        name
      }
      main_office {
        name
      }
      solutions {
        id
        name
      }
      media {
        url
      }
    }
  }
`;

export const GET_NEW_PROVIDER = gql`
  query provider($id: ID!) {
    provider(id: $id, publicationState: PREVIEW) {
      name
      description
      url
      url_social_media
      size
      countries {
        id
        region
      }
      offices {
        id
        name
      }
      solutions {
        id
        name
      }
      media {
        url
      }
      main_office {
        id
      }
    }
  }
`;

export const CREATE_PROVIDER = gql`
  mutation CreateProvider($body: ProviderInput) {
    createProvider(input: { data: $body }) {
      provider {
        id
      }
    }
  }
`;

export const UPDATE_PROVIDER = gql`
  mutation UpdateProvider($id: ID!, $body: editProviderInput) {
    updateProvider(input: { where: { id: $id }, data: $body }) {
      provider {
        updated_at
      }
    }
  }
`;

export const GET_PROVIDERS_BY_SOLUTION = gql`
  query providers($start: Int!, $limit: Int!, $where: JSON) {
    providers(start: $start, limit: $limit, where: $where, sort: "name:asc") {
      providerId: id
      name
      media {
        url
        formats
      }
      size
      solutions {
        id
        name
      }
      countries {
        id
        region
      }
    }
  }
`;
