import React, { useState, useEffect } from "react";

import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";

import Layout from "components/Layout";
import HeroContactUs from "./partials/HeroContactUs";
import { sendEmail } from "../../queries/sendEmail";
import { validateEmail } from "../../config/utils";

import { loadScriptByURL } from "config/utils";

import useStyles from "./lib/useStyles";
import { getListButtons } from "../utils";

const ContactUs = () => {
  const classes = useStyles();

  loadScriptByURL();

  const listButtons = getListButtons();

  const [data, setData] = useState({
    name: "",
    email: "",
    message: "",
    acceptTerms: false,
  });

  const [messageSent, setMessageSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const sendMessage = (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    if (!validateEmail(data.email)) {
      setError("Insert a valid email address");
      setLoading(false);
      return;
    }
    if (!data.message.trim()) {
      setError("Insert a message");
      setLoading(false);
      return;
    }
    if (!data.acceptTerms) {
      setError("You must accept our Terms and Conditions");
      setLoading(false);
      return;
    }
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY, {
          action: "contactUs",
        })
        .then((token) => {
          const text =
            data.name + "(" + data.email + ") wrote: " + data.message;
          sendEmail(token, text)
            .then((res) => {
              setLoading(false);
              if (res.status < 200 || res.status >= 300) {
                setError(res.statusText);
                return;
              }
              setMessageSent(true);
              setData({ name: "", email: "", message: "", acceptTerms: false });
            })
            .catch((error) => {
              error.data ? setError(error.data.error) : setError(error);
              error.data ? console.log(error.data.error) : console.log(error);
              setLoading(false);
            });
        });
    });
  };

  const confirmation = messageSent ? <div>Message Sent!</div> : null;

  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 820
        ? setMobileView(true)
        : setMobileView(false);
    };

    setResponsiveness();

    const onResize = () => setResponsiveness();

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return (
    <>
      <Layout listButtons={listButtons} appBarColor="primary">
        <HeroContactUs />
        <Layout.Content withPadding={true}>
          <Container maxWidth="lg" className={classes.container}>
            <Box
              marginTop="1rem"
              className={
                mobileView ? classes.mainContentMobile : classes.mainContent
              }
            >
              {confirmation}
              {error ? (
                <Box marginTop="1rem" marginBottom="1rem">
                  <small className={classes.errorMessage}>{error}</small>
                </Box>
              ) : (
                ""
              )}
              <Box marginTop="2rem">
                <TextField
                  label="Name"
                  onChange={(e) => setData({ ...data, name: e.target.value })}
                  fullWidth
                  variant="outlined"
                  value={data.name}
                />
              </Box>
              <Box marginTop="2rem">
                <TextField
                  label="Email"
                  onChange={(e) => setData({ ...data, email: e.target.value })}
                  required
                  fullWidth
                  type="email"
                  variant="outlined"
                  value={data.email}
                />
              </Box>
              <Box marginTop="2rem">
                <TextField
                  label="Message"
                  onChange={(e) =>
                    setData({ ...data, message: e.target.value })
                  }
                  required
                  fullWidth
                  multiline
                  rowsMax={4}
                  variant="outlined"
                  InputProps={{ classes: { input: classes.message } }}
                  value={data.message}
                />
              </Box>
              <Box marginTop="2rem">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={data.acceptTerms}
                      onChange={(e) =>
                        setData({ ...data, acceptTerms: e.target.checked })
                      }
                      name="acceptTerms"
                      id="acceptTerms"
                      color="primary"
                    />
                  }
                  label="Accept Terms and Conditions"
                />
              </Box>
              <Box marginTop="3rem" marginBottom="3rem">
                <Button
                  className={classes.confirmationButton}
                  title="Send message"
                  onClick={sendMessage}
                  disabled={loading}
                >
                  {loading ? "Submitting..." : "Send message"}
                </Button>
              </Box>
              <Box marginTop="3rem" marginBottom="3rem">
                <Typography variant="h4" className={classes.smallText}>
                  Personal data is collected and processed in accordance with
                  DHI Privacy Policy.
                  <br />
                  For information on processing of personal data please refer to
                  our{" "}
                  <a
                    target="_target"
                    href={"https://www.dhigroup.com/privacy"}
                    title="DHI Privacy Policy"
                  >
                    Privacy Policy.
                  </a>
                </Typography>
              </Box>
            </Box>
          </Container>
        </Layout.Content>
      </Layout>
    </>
  );
};

export default ContactUs;
