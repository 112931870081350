import React from "react";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const Challenges = ({ challenges = [] }) => {
  return (
    <Box marginTop="2rem">
      <Typography gutterBottom variant="body1" color="textSecondary">
        Related Challenges
      </Typography>
      <Box>
        {challenges.map(({ displayName }) => (
          <Typography variant="body1" key={displayName}>
            {displayName}
          </Typography>
        ))}
      </Box>
    </Box>
  );
};

export default Challenges;
