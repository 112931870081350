import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  mainContent: {
    backgroundColor: theme.palette.primary.contrastText,
    marginLeft: "8rem",
    marginRight: "8rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  mainContentMobile: {
    backgroundColor: theme.palette.primary.contrastText,
    marginTop: "-4rem",
    marginLeft: "1rem",
    marginRight: "1rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  mainTitle: {
    textAlign: "center",
    marginTop: "3.75rem",
    marginBottom: "2rem",
  },
  subTitle: {
    marginLeft: "22%",
    fontSize: "1rem",
  },
  subTitleMobile: {
    marginLeft: "1rem",
    fontSize: "1rem",
  },
  subTitleMiddle: {
    marginLeft: "22%",
    fontSize: "1rem",
    marginTop: "2rem",
  },
  subTitleMiddleMobile: {
    marginLeft: "1rem",
    fontSize: "1rem",
    marginTop: "2rem",
  },
  subTitleCentered: {
    textAlign: "center",
    marginTop: "3.75rem",
    marginLeft: "1rem",
    marginRight: "1rem",
    marginBottom: "2rem",
  },
  subTitleNote: {
    textAlign: "center",
    marginTop: "2rem",
    marginBottom: "2rem",
    fontSize: "0.75rem",
  },
  subTitleReview: {
    textAlign: "center",
    marginTop: "2rem",
    marginBottom: "2rem",
  },
  smallText: {
    textAlign: "center",
    color: theme.palette.darkGrey.main,
    fontSize: "0.6rem",
  },
  searchFieldBox: {
    marginTop: "2rem",
    textAlign: "center",
  },
  searchField: {
    alignItems: "normal",
  },
  fields: {
    marginTop: "1rem",
    marginLeft: "22%",
    width: "60%",
  },
  fieldsMobile: {
    marginTop: "1rem",
    marginLeft: "1rem",
    marginRight: "1rem",
  },
  radioItem: {
    marginLeft: "0px",
    marginBottom: "0.5rem",
    paddingleft: "0.62rem",
  },
  divider: {
    height: "2px",
  },
  textField: {
    marginBottom: "1rem",
    paddingTop: "12px",
  },
  formControl: {
    width: "100%",
    marginBottom: "1.5rem",
  },
  inputLabel: {
    color: theme.palette.darkGrey.main,
    "&.Mui-focused": {
      color: theme.palette.darkGrey.main,
    },
  },
  error: {
    color: theme.palette.error.main,
  },
  helperText: {
    color: theme.palette.darkGrey.main,
  },
  form: {
    marginBottom: "2rem",
  },
}));
