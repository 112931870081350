import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(() => ({
  chipSolution: {
    width: "100%",
    height: "80px",
  },
  chipSolutionLabel: {
    fontWeight: "bold",
    fontSize: "0.9rem",
  },
}));
