import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  buttons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    flexWrap: "wrap",
    height: "40vh",
    border: "2px dashed " + theme.palette.darkGrey.dark,
    background: theme.palette.lightGrey.main,
    boxSizing: "border-box",
    borderRadius: "28px",
  },

  button: {
    "&:hover": {
      color: theme.palette.secondary.main,
      background: "transparent",
    },
    "&:active": {
      color: theme.palette.secondary.main,
      background: "transparent",
    },
    "&:focus": {
      color: theme.palette.secondary.main,
      background: "transparent",
    },
  },

  images: {
    display: "flex",
    justifyContent: "center",
  },

  delete: {
    root: {
      position: "relative",
      top: "16px",
      right: "15px",
    },

    svg: {
      root: {
        filter: "drop-shadow(1px 1px 1px #999)",
      },
      "&:hover": {
        filter: "drop-shadow(1.5px 1.5px 1.5px)",
      },
    },
  },

  fileInput: {
    display: "none",
  },

  img: {
    maxHeight: "420px",
    maxWidth: "420px",
    padding: "10px",
    border: "1px dashed " + theme.palette.darkGrey.dark,
  },

  imagePreview: {
    verticalAlign: "middle",
    width: "100%",
    height: "100%",
  },

  imageLabel: {
    padding: "1vh 3vw",
    color: theme.palette.secondary.main,
    background: theme.palette.secondary.contrastText,
    border: "2px solid " + theme.palette.secondary.main,
    borderRadius: "4px",
    fontWeight: "bold",
  },

  uploadLogo: {
    marginRight: "12px",
  },

  error: {
    color: theme.palette.error.main,
  },
  helperText: {
    color: theme.palette.darkGrey.main,
  },
}));
