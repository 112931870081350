import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.mediumGrey.main,
  },
}));
