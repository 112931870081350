export const listButtons = [
  {
    label: "Goal",
    href: "/about",
    isActive: true,
    isNotActive: false,
  },
  {
    label: "Add solution provider",
    href: "/add-company-intro",
    isButton: true,
    isActive: true,
    isNotActive: false,
  },
];
