import { makeStyles } from "@material-ui/core/styles";
import heroLogoBackground from "assets/HeroHome.png";

export default makeStyles((theme) => ({
  hero: {
    backgroundImage: `url(${heroLogoBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "100%",
    minHeight: "580px",
  },
  heroContent: {
    marginTop: "8rem",
    width: "100%",
    textAlign: "center",
  },
  heroText: {
    color: theme.palette.secondary.contrastText,
  },
}));
