import { gql } from "@apollo/client";

export const GET_PROVIDERS_COUNT = gql`
  {
    providersConnection {
      aggregate {
        count
      }
    }
  }
`;

export const GET_SOLUTIONS_FILTERED_COUNT = gql`
  query solutionsByFilterCollection(
    $challenge_name: String
    $country_id: Int
    $name_contains: String
    $provider_name_contains: String
  ) {
    solutionsByFilterCollection(
      challenge_name: $challenge_name
      country_id: $country_id
      name_contains: $name_contains
      provider_name_contains: $provider_name_contains
    ) {
      count
    }
  }
`;

export const GET_PROVIDERS_PUBLISHED_COUNT = gql`
  query providersConnection {
    providersConnection(where: { published_at_null: false }) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_PROVIDERS_BY_SOLUTION_COUNT = gql`
  query providersConnection($where: JSON) {
    providersConnection(where: $where) {
      aggregate {
        count
      }
    }
  }
`;
