import React, { useState, useEffect } from "react";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import { Typography } from "@material-ui/core";

import useStyles from "./lib/useStyles";

const Range = ({ title, list, min, max }) => {
  const classes = useStyles();

  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 820
        ? setMobileView(true)
        : setMobileView(false);
    };

    setResponsiveness();

    const onResize = () => setResponsiveness();

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return (
    <>
      <Typography variant="body2" className={classes.title}>
        {title}
      </Typography>
      <Box className={mobileView ? classes.containerMobile : classes.container}>
        {list.map((label, index) => {
          const step = index + 1;
          const getIsActive = (count) => count >= min && count <= max;
          const isActive = getIsActive(step);
          const isPreviousActive = getIsActive(step - 1);
          const isPreviousInRange = index - 1 >= 0;
          const isLineActive =
            isActive && isPreviousActive && isPreviousInRange;
          return (
            <Box key={index} className={classes.stepContainer}>
              <Box className={classes.circleContainer}>
                <Box
                  className={clsx(classes.circle, {
                    [classes.circleIsActive]: isActive,
                  })}
                >
                  <Box className={classes.count}>{step}</Box>
                </Box>
                <Box
                  className={clsx(classes.line, {
                    [classes.lineIsActive]: isLineActive,
                    [classes.lineIsNotActive]: !isLineActive,
                    [classes.lineIsNotInRange]: !isPreviousInRange,
                  })}
                />
              </Box>
              {!mobileView && (
                <Box className={classes.labelContainer}>
                  {label && (
                    <Typography
                      variant="caption"
                      color="inherit"
                      align="center"
                      className={clsx(classes.label, {
                        [classes.labelIsActive]: isActive,
                      })}
                    >
                      {label}
                    </Typography>
                  )}
                </Box>
              )}
            </Box>
          );
        })}
      </Box>
    </>
  );
};

export default Range;
