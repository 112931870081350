import React from "react";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";

import unepLogo from "assets/logoUnepDhiPartners.svg";
import ctcnLogo from "assets/logoCtcn.svg";
import iucnLogo from "assets/logoIucn.svg";
import unepDtuLogo from "assets/logoDtu.png";

import { ReactComponent as EcoLogo } from "assets/iconEco.svg";

const Footer = () => {
  return (
    <Box bgcolor="primary.contrastText">
      <Container maxWidth="lg">
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={8}>
            <Box width={1} paddingY="2rem">
              <Typography variant="body2" color="primary">
                Match! Water Solutions Portal is brought to you by the following
                partners:
              </Typography>
              <Box
                marginTop="1rem"
                display="flex"
                alignItems={{
                  xs: "flex-start",
                  sm: "flex-start",
                  md: "flex-start",
                  lg: "center",
                }}
                overflow="hidden"
                flexDirection={{
                  xs: "column",
                  sm: "column",
                  md: "column",
                  lg: "row",
                }}
              >
                <a
                  target="_target"
                  href={"https://www.unepdhi.org/"}
                  title="UNEP Home page"
                >
                  <img
                    alt="UNEP Logo"
                    src={unepLogo}
                    style={{ width: "auto", height: 110, padding: 10 }}
                  />
                </a>
                <a
                  target="_target"
                  href={"https://www.ctc-n.org/"}
                  title="CTCN Home page"
                >
                  <img
                    alt="CTCN Logo"
                    src={ctcnLogo}
                    style={{ width: "auto", height: 110, padding: 10 }}
                  />
                </a>
                <a
                  target="_target"
                  href={"https://www.iucn.org/"}
                  title="IUCN Home page"
                >
                  <img
                    alt="IUCN Logo"
                    src={iucnLogo}
                    style={{ width: 100, height: "auto", padding: 10 }}
                  />
                </a>
                <a
                  target="_target"
                  href={"https://unepdtu.org/"}
                  title="UNEP DTU Home page"
                >
                  <img
                    alt="UNEP DTU Logo"
                    src={unepDtuLogo}
                    style={{ width: "auto", height: 100, padding: 10 }}
                  />
                </a>
              </Box>
              <Box display="flex" alignItems="center" overflow="hidden">
                <Typography variant="body2" color="primary">
                  Supporting nature based solutions
                </Typography>
                <EcoLogo style={{ marginLeft: "5px" }} />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <Box
              width={1}
              paddingY="2.2rem"
              display="flex"
              flexDirection="column"
              alignItems={{
                xs: "flex-start",
                sm: "flex-end",
                md: "flex-end",
                lg: "flex-end",
              }}
            >
              <Typography variant="body2" color="primary">
                Menu:
              </Typography>
              <List
                component={Box}
                display="flex"
                flexDirection="column"
                alignItems={{
                  xs: "flex-start",
                  sm: "flex-end",
                  md: "flex-end",
                  lg: "flex-end",
                }}
              >
                <ListItem
                  component={Link}
                  to="/"
                  dense
                  disableGutters
                  style={{ width: "auto" }}
                >
                  <ListItemText
                    primary="Home"
                    primaryTypographyProps={{
                      style: {
                        fontWeight: "bold",
                        fontSize: "0.75rem",
                        lineHeight: "0.5rem",
                      },
                    }}
                  />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/add-company-intro"
                  dense
                  disableGutters
                  style={{ width: "auto" }}
                >
                  <ListItemText
                    primary="Add a solution provider"
                    primaryTypographyProps={{
                      style: {
                        fontWeight: "bold",
                        fontSize: "0.75rem",
                        lineHeight: "0.5rem",
                      },
                    }}
                  />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/about"
                  dense
                  disableGutters
                  style={{ width: "auto" }}
                >
                  <ListItemText
                    primary="Goal"
                    primaryTypographyProps={{
                      style: {
                        fontWeight: "bold",
                        fontSize: "0.75rem",
                        lineHeight: "0.5rem",
                      },
                    }}
                  />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/partners"
                  dense
                  disableGutters
                  style={{ width: "auto" }}
                >
                  <ListItemText
                    primary="Partners"
                    primaryTypographyProps={{
                      style: {
                        fontWeight: "bold",
                        fontSize: "0.75rem",
                        lineHeight: "0.5rem",
                      },
                    }}
                  />
                </ListItem>
                <ListItem
                  component={Link}
                  to="/contact-us"
                  dense
                  disableGutters
                  style={{ width: "auto" }}
                >
                  <ListItemText
                    primary="Contact us"
                    primaryTypographyProps={{
                      style: {
                        fontWeight: "bold",
                        fontSize: "0.75rem",
                        lineHeight: "0.5rem",
                      },
                    }}
                  />
                </ListItem>
              </List>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
