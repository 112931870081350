import React from "react";
import { useQuery } from "@apollo/client";

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";

import { GET_PROVIDERS_PUBLISHED_COUNT } from "queries/counts";

import useStyles from "./lib/useStyles";

const AddCompanyHero = () => {
  const classes = useStyles();

  const { data: dataProviders = {} } = useQuery(GET_PROVIDERS_PUBLISHED_COUNT);
  const { providersConnection = { aggregate: { count: 0 } } } = dataProviders;

  return (
    <Box classes={{ root: classes.hero }}>
      <Container maxWidth="lg">
        <Box display="flex" overflow="hidden">
          <Box classes={{ root: classes.heroContent }}>
            <Typography variant="h4" className={classes.heroText} gutterBottom>
              Add Solution Provider
            </Typography>
            <Typography
              gutterBottom
              variant="h3"
              className={classes.heroText}
              style={{ fontWeight: "normal" }}
            >
              Is your company solving the world’s biggest
              <br />
              challenges?
              <br />
              Become one of{" "}
              <Box display="inline">
                {providersConnection.aggregate.count}+
              </Box>{" "}
              providers of solutions
              <br />
              all over the globe
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default AddCompanyHero;
