import { atom } from "recoil";

const filterState = atom({
  key: "filterState",
  default: {
    challenge: "all",
    location: "all",
    techSolution: "",
    provider: "",
  },
});

export default filterState;
