import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  buttonsRow: {
    marginTop: "20px",
    marginRight: "44px",
    marginBottom: "32px",
    display: "flex",
    justifyContent: "flex-end",
  },
  backButton: {
    color: theme.palette.secondary.main,
    marginRight: "24px",
    textTransform: "none",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  nextButton: {
    width: "156px",
    height: "48px",
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  nextButtonDisabled: {
    width: "156px",
    height: "48px",
    backgroundColor: theme.palette.secondary.light + "!important",
    color: theme.palette.secondary.contrastText + "!important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.palette.secondary.light + "!important",
    },
  },
}));
