import React, { useState, useEffect } from "react";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import Layout from "components/Layout";
import HeroAbout from "./partials/HeroAbout";
import useStyles from "./lib/useStyles";
import { getListButtons } from "../utils";

import { removeRecaptcha } from "config/utils";

const About = () => {
  const classes = useStyles();
  const listButtons = getListButtons();

  removeRecaptcha();

  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 820
        ? setMobileView(true)
        : setMobileView(false);
    };

    setResponsiveness();

    const onResize = () => setResponsiveness();

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return (
    <>
      <Layout listButtons={listButtons} appBarColor="primary">
        <HeroAbout />
        <Layout.Content withPadding={true}>
          <Box
            className={
              mobileView ? classes.mainContentMobile : classes.mainContent
            }
          >
            <Typography variant="h6" color="primary" className={classes.text}>
              The Match! Water Solutions Portal helps to establish direct
              contact between practitioners and decision makers with water
              challenges and solution providers and has no further involvement
              after helping to make the match.
            </Typography>
            <Typography variant="h6" color="primary" className={classes.text}>
              The water solutions available are applied over different spatial
              scales, timescales and cost ranges. They include hard technologies
              such as physical tools, soft technologies such as processes and
              knowledge, and organizational technologies referring to ownership
              and management solutions.
            </Typography>
            <br />
            <Typography
              variant="h6"
              color="primary"
              className={classes.text}
              style={{ fontWeight: "bold" }}
            >
              The Match! Water Solutions Portal aims to add value in 3 key
              areas:
            </Typography>
            <Typography variant="h6" color="primary" className={classes.text}>
              1) Practitioners and decision makers with water-related challenges
              are guided towards solutions they may not otherwise have known
              about for free
            </Typography>
            <Typography variant="h6" color="primary" className={classes.text}>
              2) Solution providers have their products and knowledge
              disseminated for free in a way that might expand their markets
            </Typography>
            <Typography variant="h6" color="primary" className={classes.text}>
              3) Water related challenges are addressed and countries progress
              towards achieving the Sustainable Development Goals
            </Typography>
            <Typography
              variant="h5"
              color="primary"
              className={classes.titleMiddle}
            >
              Background
            </Typography>
            <Typography variant="h6" color="primary" className={classes.text}>
              Water is central to our survival. Socio-economic activities,
              including agricultural and industrial production and energy
              generation, are dependent on the sustainable use of water. It’s
              essential role, combined with the challenges of satisfying growing
              demands while addressing increasing pressures, is the main reason
              why water has a dedicated Sustainable Development Goal with
              several supporting targets.
            </Typography>
            <Typography variant="h6" color="primary" className={classes.text}>
              Humankind already has the knowledge and technological solutions to
              address some of the greatest water challenges. The difficulty we
              face is bringing the people with the challenges and the people
              with the solutions together. The purpose of the Match! Water
              Solutions Portal, a digital knowledge sharing and technology
              transfer tool, is to address the situation.
            </Typography>
            <Typography
              variant="h5"
              color="primary"
              className={classes.titleMiddle}
            >
              Disclaimer
            </Typography>
            <Typography variant="h6" color="primary" className={classes.text}>
              The technology solution information presented on this website has
              been adapted from the Climate Change Adaptation Technologies for
              Increased Water Sector Resilience{" "}
              <a
                target="_target"
                href={
                  "https://www.unepdhi.org/wp-content/uploads/sites/2/2020/05/CC_Adaptation_technologies_for_water_red.pdf"
                }
              >
                report
              </a>
              . The content of the Match! Water Solutions Portal is for
              information and educational purposes only and no legal liability
              or other responsibility is accepted by or on behalf of UNEP-DHI
              for any misinformation on this site, or any site to which these
              pages connect. UNEP-DHI accepts no responsibility for any loss,
              damage or inconvenience caused as a result of reliance on the
              information provided.
            </Typography>
          </Box>
        </Layout.Content>
      </Layout>
    </>
  );
};

export default About;
