import React from "react";
import { NavLink } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";

import clsx from "clsx";

import useStyles from "./lib/useStyles";

const SimpleMenu = ({ menuItems, appBarColor, isActive, isNotActive }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const classes = useStyles({});

  return (
    <div>
      <Button
        aria-controls="about-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={classes.link}
      >
        <Typography
          variant="body2"
          className={clsx(
            {
              [classes.labelWhite]: appBarColor === "primary",
            },
            {
              [classes.labelBlue]: appBarColor !== "primary",
            },
            {
              [classes.labelIsActive]: isActive,
            },
            {
              [classes.labelWhiteIsNotActive]:
                appBarColor === "primary" && isNotActive,
            },
            {
              [classes.labelBlueIsNotActive]:
                appBarColor !== "primary" && isNotActive,
            }
          )}
        >
          About
        </Typography>
      </Button>
      <Menu
        id="about-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuItems.map(({ label, href }) => {
          return (
            <MenuItem onClick={handleClose} key={label}>
              <NavLink className={classes.menuItem} to={href}>
                {label}
              </NavLink>
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

export default SimpleMenu;
