import React, { useReducer, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { useQuery } from "@apollo/client";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import DoneIcon from "@material-ui/icons/Done";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import CircularProgress from "@material-ui/core/CircularProgress";

import Pagination from "components/Pagination";
import { GET_SOLUTIONS_FILTERED_COUNT } from "queries/counts";
import { GET_SOLUTIONS_BY_FILTER } from "queries/solutions";

import filterTechSolutionState from "../Step2/filterTechSolutionState";
import reducer from "../Step2/reducerTechSolution";
import initialState from "../../../Home/partials/Solutions/lib/initialState";
import myUseStyles from "./lib/myUseStyles";
import useStyles from "../../lib/useStyles";

const SelectableSolutions = ({
  name,
  value,
  setFieldValue,
  errors = {},
  getHelperText,
}) => {
  const myClasses = myUseStyles();
  const classes = useStyles();
  const limit = 24;
  const [state, dispatch] = useReducer(reducer, initialState);
  const filter = useRecoilValue(filterTechSolutionState);

  const { data: dataCounter = {}, loading: loadingCounter } = useQuery(
    GET_SOLUTIONS_FILTERED_COUNT,
    {
      variables: { ...state },
    }
  );
  const { solutionsByFilterCollection = { count: 0 } } = dataCounter;

  useEffect(() => {
    dispatch({ type: "filter", payload: filter, limit: limit });
  }, [filter]);

  const [selectedItems, setselectedItems] = useState(value);

  const { data: dataSolutions = {}, loading: loadingSolutions } = useQuery(
    GET_SOLUTIONS_BY_FILTER,
    {
      variables: { ...state, limit: limit },
    }
  );
  const { solutionsByFilter = [] } = dataSolutions;

  const handleOnClick = (item) => {
    let newArray = [...selectedItems];
    if (isSelected(item.id)) {
      const elementsIndex = newArray.findIndex(
        (element) => element.name === name
      );
      newArray.splice(elementsIndex, 1);
    } else {
      newArray = [...selectedItems, { id: item.id, name: item.name }];
    }
    setselectedItems(newArray);
    setFieldValue(name, newArray);
  };

  const isSelected = (id) => {
    const elementIndex = selectedItems.findIndex((item) => item.id + "" === id);
    return elementIndex !== -1;
  };

  const handleOnChange = (event, value) => {
    dispatch({ type: "page", payload: value, limit: limit });
  };

  return (
    <Box marginTop="3rem" marginRight="1rem" marginLeft="1rem">
      {loadingCounter || loadingSolutions ? (
        <CircularProgress />
      ) : (
        <>
          <FormControl className={classes.formControl}>
            <Grid container spacing={3}>
              <>
                {solutionsByFilter.map((solution) => (
                  <Grid item xs={12} md={3} lg={3} key={solution.id}>
                    <Chip
                      className={myClasses.chipSolution}
                      size="medium"
                      label={
                        <Typography
                          variant="h6"
                          className={myClasses.chipSolutionLabel}
                        >
                          {solution.name}
                        </Typography>
                      }
                      title={solution.name}
                      key={solution.id}
                      onClick={() => {
                        handleOnClick(solution);
                      }}
                      icon={isSelected(solution.id) ? <DoneIcon /> : null}
                    />
                  </Grid>
                ))}
              </>
            </Grid>
            {solutionsByFilterCollection.count > 0 && (
              <Box marginTop="3rem">
                <FormHelperText
                  className={
                    errors["solutions"] ? classes.error : classes.helperText
                  }
                >
                  {getHelperText(errors, "solutions", "* = required")}
                </FormHelperText>
              </Box>
            )}
          </FormControl>
          <Box display="flex" justifyContent="center" marginY="3rem">
            {solutionsByFilterCollection.count > 0 ? (
              <Pagination
                counter={solutionsByFilterCollection.count}
                limit={limit}
                page={state.page}
                handleOnChange={handleOnChange}
              />
            ) : (
              <Typography variant="h6">
                No content match your search criteria
              </Typography>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default SelectableSolutions;
