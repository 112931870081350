import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import Layout from "components/Layout";
import AddCompanyHero from "../AddCompany/partials/AddCompanyHero";
import useStyles from "./lib/useStyles";

import { removeRecaptcha } from "config/utils";

const AddCompanyIntro = () => {
  const classes = useStyles();

  const listButtons = getListButtons();

  removeRecaptcha();

  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 820
        ? setMobileView(true)
        : setMobileView(false);
    };

    setResponsiveness();

    const onResize = () => setResponsiveness();

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return (
    <>
      <Layout listButtons={listButtons} appBarColor="secondary">
        <AddCompanyHero />
        <Layout.Content>
          <Box className={mobileView ? classes.introMobile : classes.intro}>
            <Typography
              variant="h5"
              color="primary"
              className={classes.titleIntro}
            >
              For solution providers
            </Typography>
            <Typography
              variant="h6"
              color="primary"
              className={classes.textIntro}
            >
              Adding your company to the Match! Portal will help people with
              water challenges to find your company details and contact you
              directly for a solution. The benefits include:
            </Typography>
            <Typography
              variant="h6"
              color="primary"
              className={classes.textIntro}
            >
              <ul>
                <li>Sharing your technologies with potential users</li>
                <li>
                  Having your products and knowledge shared in a way that might
                  expand your markets
                </li>
                <li>
                  Free platform for sharing information about your company and
                  the technologies you provide
                </li>
              </ul>
            </Typography>
            <Typography
              variant="h6"
              color="primary"
              className={classes.textIntro}
            >
              The Match! Portal has no further involvement beyond helping to
              establish direct contact between people with water challenges and
              companies providing water solutions.
            </Typography>
            <Typography
              variant="h6"
              color="primary"
              className={classes.textIntro}
            >
              Fill in the form below and your company will be added to the
              Match! Portal as a solution provider, helping to solve the world's
              water challenges.
            </Typography>
            <Box className={classes.divButton}>
              <Button
                component={Link}
                to="/login"
                className={
                  mobileView
                    ? classes.confirmationButtonMobile
                    : classes.confirmationButton
                }
                title="Go to solution provider form"
                color="primary"
                autoFocus
              >
                Go to solution provider form
              </Button>
            </Box>
          </Box>
        </Layout.Content>
      </Layout>
    </>
  );
};

const getListButtons = () => {
  const list = [
    {
      label: "Goal",
      href: "/about",
      isActive: false,
      isNotActive: true,
    },
  ];

  if (
    localStorage.getItem("token") &&
    localStorage.getItem("token") !== "undefined" &&
    localStorage.getItem("userProviderId") &&
    localStorage.getItem("userProviderId") !== "-1"
  ) {
    list.push({
      label: "Edit solution provider",
      href: "/add-company",
      isButton: true,
      isActive: true,
      isNotActive: false,
    });
  } else {
    list.push({
      label: "Add solution provider",
      href: "/add-company-intro",
      isButton: true,
      isActive: true,
      isNotActive: false,
    });
  }
  return list;
};

export default AddCompanyIntro;
