import React from "react";
import { useRecoilState } from "recoil";
import debounce from "lodash.debounce";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import SearchIcon from "@material-ui/icons/Search";

import Field from "components/Field";
import MainTitle from "../MainTitle/MainTitle";
import useStyles from "../../lib/useStyles";

import SelectableSolutions from "../SelectableSolutions";
import filterTechSolutionState from "./filterTechSolutionState";

const Step2 = ({ values, setFieldValue, errors = {}, getHelperText }) => {
  const classes = useStyles();
  const [filter, setFilter] = useRecoilState(filterTechSolutionState);

  return (
    <Box>
      <MainTitle />
      <Typography
        variant="h6"
        color="primary"
        className={classes.subTitleCentered}
      >
        Solutions that the company can provide *
      </Typography>
      <Typography variant="h4" className={classes.smallText}>
        Click and select on one or more of the solutions below.
      </Typography>
      <Box className={classes.searchFieldBox}>
        <Field
          placeholder="Search for a solution"
          defaultValue={filter.techSolution}
          onChange={debounce(
            (value) => setFilter({ ...filter, techSolution: value }),
            1000
          )}
          icon={SearchIcon}
          className={classes.searchField}
        />
        <Box>
          <SelectableSolutions
            name="step2.solutions"
            value={values.solutions}
            setFieldValue={setFieldValue}
            errors={errors}
            getHelperText={getHelperText}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Step2;
