import React, { useState } from "react";

import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";

import Layout from "components/Layout";
import AlertDialog from "components/AlertDialog";

import { forgotPassword } from "queries/auth";
import { validateEmail, removeRecaptcha } from "config/utils";

import useStyles from "../lib/useStyles";
import { listButtons } from "../utilsAuthentication";

const ForgotPassword = () => {
  const classes = useStyles();

  const [openAlert, setOpenAlert] = useState(false);

  const [data, setData] = useState({ email: "" });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});

  const submit = (e) => {
    e.preventDefault();
    setLoading(true);
    setError({});

    forgotPassword(data.email)
      .then((res) => {
        setLoading(false);
        setOpenAlert(true);
        setData({ email: "" });
      })
      .catch((error) => {
        error.data ? setError(error.data) : setError(error);
        setLoading(false);
      });
  };

  removeRecaptcha();

  return (
    <>
      <Layout listButtons={listButtons} appBarColor="transparent">
        <Layout.Content>
          <Box className={classes.mainBox}>
            <Container maxWidth="lg" className={classes.container}>
              <Box className={classes.innerBox}>
                <section className={classes.wrapper}>
                  <Typography
                    variant="h4"
                    color="primary"
                    style={{ fontWeight: "normal" }}
                  >
                    Find your account
                  </Typography>
                  <Box marginTop="2rem" marginBottom="2rem">
                    <Divider className={classes.divider} />
                  </Box>
                  {Object.entries(error).length !== 0 &&
                    error.constructor === Object &&
                    error.message.map((error) => {
                      return (
                        <div
                          key={error.messages[0].id}
                          style={{ marginBottom: 10 }}
                        >
                          <small style={{ color: "red" }}>
                            {error.messages[0].message}
                          </small>
                        </div>
                      );
                    })}
                  <Box marginTop="2rem">
                    <TextField
                      name="email"
                      label="E-mail address"
                      fullWidth
                      required
                      type="email"
                      disabled={loading}
                      onChange={(e) => {
                        if (!validateEmail(e.target.value)) {
                          setError("Insert a valid email address");
                        }
                        setData({ ...data, email: e.target.value });
                      }}
                      value={data.email}
                    />
                  </Box>
                  <Box marginTop="4rem" marginBottom="2rem">
                    <Button
                      className={classes.confirmationButton}
                      onClick={submit}
                      title="Search"
                      disabled={loading}
                    >
                      Search
                    </Button>
                  </Box>
                </section>
              </Box>
            </Container>
          </Box>
        </Layout.Content>
      </Layout>
      {openAlert && (
        <AlertDialog
          open={openAlert}
          close={() => setOpenAlert(false)}
          title={"Forgotten password"}
          message={
            "An email was sent to the specified address.\n\nPlease click on the link in the email to reset your password."
          }
          maxWidth="sm"
        />
      )}
    </>
  );
};

export default ForgotPassword;
