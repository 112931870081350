import React from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import useStyles from "./lib/useStyles";

const HeroPartners = () => {
  const classes = useStyles();

  return (
    <Box classes={{ root: classes.hero }}>
      <Container maxWidth="lg">
        <Box display="flex" overflow="hidden">
          <Box classes={{ root: classes.heroContent }}>
            <Typography variant="h4" className={classes.heroText} gutterBottom>
              Partners
            </Typography>
            <Typography
              gutterBottom
              variant="h3"
              className={classes.heroText}
              style={{ fontWeight: "normal" }}
            >
              The Match! Portal is a collaboration between
              <br />
              several international actors working with
              <br />
              sustainable water management and the SDGs.
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default HeroPartners;
