import { gql } from "@apollo/client";

export const GET_CHALLENGES = gql`
  query challenges {
    challenges(sort: "displayName:asc") {
      displayName
      name
    }
  }
`;
