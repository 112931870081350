import React from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import useStyles from "./lib/useStyles";

const HeroContactUs = () => {
  const classes = useStyles();

  return (
    <Box classes={{ root: classes.hero }}>
      <Container maxWidth="lg">
        <Box display="flex" overflow="hidden">
          <Box classes={{ root: classes.heroContent }}>
            <Typography variant="h4" className={classes.heroText} gutterBottom>
              Contact us
            </Typography>
            <Typography
              gutterBottom
              variant="h3"
              className={classes.heroText}
              style={{ fontWeight: "normal" }}
            >
              Get in touch with us if you have any queries or
              <br />
              suggestions for additional solutions to Match!
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default HeroContactUs;
