import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

import ProviderModal from "components/ProviderModal";
import companySize from "assets/iconCompanySize.svg";
import solutionLogo from "assets/iconSolutions.svg";
import location from "assets/iconLocation.svg";

import noLogo from "assets/noLogo.png";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 450,
  },
  title: {
    backgroundColor: "#183668",
  },
  titleText: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "#FFFFFF",
    [theme.breakpoints.up("md")]: {
      width: "340px",
    },
    [theme.breakpoints.down("md")]: {
      width: "414px",
    },
    [theme.breakpoints.up("1500")]: {
      width: "414px",
    },
  },
  cardMedia: {
    backgroundColor: "white",
    objectFit: "contain !important",
  },
}));

const ProviderCard = ({
  providerId,
  name,
  media: { url, formats },
  size = "",
  solutions = [],
  countries = [],
  solutionName,
}) => {
  const classes = useStyles();
  const { thumbnail } = formats || {};
  const { url: urlSmall } = thumbnail || {};
  const bestImage = urlSmall || url;
  const image = bestImage || noLogo;

  const handleClose = () => setOpenProviderModal(false);

  const [openProviderModal, setOpenProviderModal] = useState(false);

  return (
    <>
      <Card className={classes.root}>
        <CardHeader
          title={
            <Typography
              variant={"body1"}
              component="p"
              noWrap
              title={name}
              className={classes.titleText}
            >
              {name}
            </Typography>
          }
          className={classes.title}
        />
        <CardActionArea onClick={() => setOpenProviderModal(true)}>
          {image && (
            <CardMedia
              component="img"
              alt={name}
              height="140"
              image={image}
              title={name}
              classes={{
                root: classes.cardMedia,
              }}
            />
          )}
          <CardContent style={{ height: "60px" }}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box display="flex" alignItems="center">
                <img
                  alt="provider size"
                  src={companySize}
                  style={{ marginRight: "5px" }}
                  title="Provider size"
                />
                <Typography
                  variant="body2"
                  color="primary"
                  component="p"
                  style={{ textTransform: "capitalize" }}
                  title="Provider size"
                >
                  {size}
                </Typography>
              </Box>

              <Box display="flex" alignItems="center" width="60%">
                <img alt="location" src={location} title="Provider location" />
                <Typography
                  variant="body2"
                  color="primary"
                  component="p"
                  noWrap
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "inherit",
                  }}
                  title="Provider location"
                >
                  {countries.map((x) => x.region).join(" , ")}
                </Typography>
              </Box>

              <Box display="flex" alignItems="center">
                <img
                  alt="solution"
                  src={solutionLogo}
                  title="Solutions provided"
                />
                <Typography
                  variant="body2"
                  color="primary"
                  component="p"
                  title="Solutions provided"
                >
                  {solutions && solutions.length}
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
      <ProviderModal
        isOpen={openProviderModal}
        onClose={handleClose}
        providerId={providerId}
        solutionName={solutionName}
      />
    </>
  );
};

export default ProviderCard;
