import React, { useState } from "react";

import { Redirect, Link } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import Layout from "components/Layout";
import AlertDialog from "components/AlertDialog";

import { registerUser } from "queries/auth";
import { validateEmail, loadScriptByURL } from "config/utils";

import useStyles from "../lib/useStyles";

import { listButtons } from "../utilsAuthentication";

const RegisterUser = () => {
  const classes = useStyles();

  const [openAlert, setOpenAlert] = useState(false);

  const [data, setData] = useState({
    email: "",
    username: "",
    password: "",
    acceptTerms: false,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});

  const submit = (e) => {
    e.preventDefault();
    setLoading(true);
    setError({});

    if (!data.username.trim()) {
      setError("Insert a username");
      setLoading(false);
      return;
    }
    if (!validateEmail(data.email)) {
      setError("Insert a valid email address");
      setLoading(false);
      return;
    }
    if (!data.password.trim()) {
      setError("Insert a password");
      setLoading(false);
      return;
    }
    if (!data.acceptTerms) {
      setError("You must accept our Terms and Conditions");
      setLoading(false);
      return;
    }

    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY, {
          action: "registerUser",
        })
        .then((token) => {
          registerUser(data.username, data.email, data.password, token)
            .then((res) => {
              setLoading(false);
              setOpenAlert(true);
              setData({
                email: "",
                username: "",
                password: "",
                acceptTerms: false,
              });
            })
            .catch((error) => {
              error.data ? setError(error.data) : setError(error);
              setLoading(false);
            });
        });
    });
  };

  loadScriptByURL();

  if (
    localStorage.getItem("token") &&
    localStorage.getItem("token") !== "undefined"
  ) {
    return <Redirect to="/add-company" />;
  } else {
    return (
      <>
        <Layout listButtons={listButtons} appBarColor="transparent">
          <Layout.Content>
            <Box className={classes.mainBox}>
              <Container maxWidth="lg" className={classes.container}>
                <Box className={classes.innerBox}>
                  <section className={classes.wrapper}>
                    <Typography
                      variant="h4"
                      color="primary"
                      style={{ fontWeight: "normal" }}
                    >
                      Sign up
                    </Typography>
                    <Box marginTop="2rem" marginBottom="2rem">
                      <Divider className={classes.divider} />
                    </Box>
                    {Object.entries(error).length !== 0 &&
                      error.constructor === Object &&
                      error.message.map((error) => {
                        return (
                          <div
                            key={error.messages[0].id}
                            style={{ marginBottom: 10 }}
                          >
                            <small style={{ color: "red" }}>
                              {error.messages[0].message}
                            </small>
                          </div>
                        );
                      })}
                    <Box marginTop="2rem">
                      <TextField
                        name="username"
                        label="User name"
                        fullWidth
                        required
                        type="text"
                        onChange={(e) =>
                          setData({ ...data, username: e.target.value })
                        }
                        value={data.username}
                      />
                      <TextField
                        name="email"
                        label="E-mail address"
                        fullWidth
                        required
                        type="email"
                        onChange={(e) => {
                          if (!validateEmail(e.target.value)) {
                            setError("Insert a valid email address");
                          }
                          setData({
                            ...data,
                            email: e.target.value,
                          });
                        }}
                        value={data.email}
                      />
                      <TextField
                        name="password"
                        label="Password"
                        fullWidth
                        required
                        type="password"
                        onChange={(e) =>
                          setData({ ...data, password: e.target.value })
                        }
                        value={data.password}
                      />
                    </Box>
                    <Box marginTop="2rem">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={data.acceptTerms}
                            onChange={(e) =>
                              setData({
                                ...data,
                                acceptTerms: e.target.checked,
                              })
                            }
                            name="acceptTerms"
                            id="acceptTerms"
                            color="primary"
                          />
                        }
                        label="Accept Terms and Conditions"
                      />
                    </Box>
                    <Box marginTop="3rem">
                      <Button
                        className={classes.confirmationButton}
                        onClick={submit}
                        title="Sign up"
                        disabled={loading}
                      >
                        Sign up
                      </Button>
                    </Box>
                    <Box marginTop="3rem" marginBottom="3rem">
                      <Typography variant="h4" className={classes.smallText}>
                        Personal data is collected and processed in accordance
                        with DHI Privacy Policy.
                        <br />
                        For information on processing of personal data please
                        refer to our{" "}
                        <a
                          target="_target"
                          href={"https://www.dhigroup.com/privacy"}
                          title="DHI Privacy Policy"
                        >
                          Privacy Policy.
                        </a>
                      </Typography>
                    </Box>
                    <Box marginTop="2rem">
                      <Divider className={classes.divider} />
                    </Box>
                    <Box marginTop="4rem">
                      <Link className={classes.linkForgotten} to={"/login"}>
                        <Typography
                          variant="body1"
                          className={classes.textForgotten}
                        >
                          Log in
                        </Typography>
                      </Link>
                    </Box>
                  </section>
                  <Box marginTop="4rem">
                    <Typography variant="body1" className={classes.textNote}>
                      Add a solution provider so business can find you
                    </Typography>
                  </Box>
                </Box>
              </Container>
            </Box>
          </Layout.Content>
        </Layout>
        {openAlert && (
          <AlertDialog
            open={openAlert}
            close={() => setOpenAlert(false)}
            title={"Thank you for registering!"}
            message={
              "An email was sent to the specified address.\n\nPlease click on the confirm link in the email."
            }
            maxWidth="sm"
          />
        )}
      </>
    );
  }
};

export default RegisterUser;
