import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

import useStyles from "../../lib/useStyles";
import MainTitle from "../MainTitle";
import ImageUpload from "../ImageUpload";

const Step3 = ({
  values,
  setFieldValue,
  onBlur,
  errors = {},
  getHelperText,
  mobileView,
}) => {
  const classes = useStyles();

  return (
    <Box>
      <MainTitle />
      <Typography
        variant="h6"
        color="primary"
        className={classes.subTitleCentered}
      >
        Upload logo *
      </Typography>
      <Typography variant="h4" className={classes.smallText}>
        The logo of the company should be in the format .svg; .png or jpeg.
      </Typography>
      <Box className={mobileView ? classes.fieldsMobile : classes.fields}>
        <ImageUpload
          name="step3.companyLogo"
          imageValue={values.companyLogo.url}
          setFieldValue={setFieldValue}
          onBlur={onBlur}
          errors={errors}
          getHelperText={getHelperText}
        />
      </Box>
      <Typography
        variant="h6"
        color="primary"
        className={classes.subTitleCentered}
      >
        Contact information
      </Typography>
      <Box
        marginTop="1rem"
        className={mobileView ? classes.fieldsMobile : classes.fields}
      >
        <Typography
          variant="h4"
          color="primary"
          className={classes.subTitleNote}
        >
          Contact information for a person that the UNEP-DHI centre can contact
          in case we have questions about the company information you are
          providing. This contact information will not appear in the Match!
          Portal.
        </Typography>
      </Box>
      <Box className={mobileView ? classes.fieldsMobile : classes.fields}>
        <TextField
          name="step3.contactName"
          label="Contact information for a person UNEP-DHI can contact"
          value={localStorage.getItem("username")}
          fullWidth
          variant="filled"
        />
        <TextField
          name="step3.contactEmail"
          label="E-mail address of contact person"
          value={localStorage.getItem("email")}
          fullWidth
          variant="filled"
        />
      </Box>
    </Box>
  );
};

export default Step3;
