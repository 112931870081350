import { gql } from "@apollo/client";

export const UPDATE_USER = gql`
  mutation UpdateUser($id: ID!, $providerId: ID!) {
    updateUser(
      input: { where: { id: $id }, data: { providers: $providerId } }
    ) {
      user {
        updated_at
      }
    }
  }
`;
