import React, { useState, useEffect } from "react";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import Layout from "components/Layout";
import HeroPartners from "./partials/HeroPartners";
import useStyles from "./lib/useStyles";

import unepLogo from "assets/logoUnepDhiPartners.svg";
import ctcnLogo from "assets/logoCtcn.svg";
import iucnLogo from "assets/logoIucn.svg";
import unepDtuLogo from "assets/logoDtu.png";

import { removeRecaptcha } from "config/utils";
import { getListButtons } from "../utils";

const Partners = () => {
  const classes = useStyles();

  const listButtons = getListButtons();

  removeRecaptcha();

  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 820
        ? setMobileView(true)
        : setMobileView(false);
    };

    setResponsiveness();

    const onResize = () => setResponsiveness();

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return (
    <>
      <Layout listButtons={listButtons} appBarColor="primary">
        <HeroPartners />
        <Layout.Content withPadding={true}>
          <Box
            className={
              mobileView ? classes.mainContentMobile : classes.mainContent
            }
          >
            <Typography variant="h6" color="primary" className={classes.text}>
              The following partners are involved with the development and
              promotion of the Match Portal:
            </Typography>
            <Box marginTop="3rem">
              <Grid container spacing={5}>
                <Grid item xs={12} lg={4}>
                  <Box width={1} display="flex" justifyContent="center">
                    <a
                      target="_target"
                      href={"https://www.unepdhi.org/"}
                      title="UNEP Home page"
                    >
                      <img
                        alt="UNEP Logo"
                        src={unepLogo}
                        style={{ width: "auto", height: 150, padding: 10 }}
                      />
                    </a>
                  </Box>
                </Grid>
                <Grid item xs={12} lg={8}>
                  <Typography
                    variant="h6"
                    color="primary"
                    className={classes.text}
                  >
                    The UNEP-DHI Centre on Water and Environment is a United
                    Nations Environment Programme (UNEP) centre of expertise,
                    dedicated to improving the management, development and use
                    of freshwater resources from the local to the global level.
                    The UNEP-DHI Centre is hosted by DHI, an independent,
                    international advisory and research organization established
                    in Denmark. DHI has more than 50 years of experience in
                    water resources management and is represented in all regions
                    of the world
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Box width={1} display="flex" justifyContent="center">
                    <a
                      target="_target"
                      href={"https://www.ctc-n.org/"}
                      title="CTCN Home page"
                    >
                      <img
                        alt="CTCN Logo"
                        src={ctcnLogo}
                        style={{ width: "auto", height: 110, padding: 10 }}
                      />
                    </a>
                  </Box>
                </Grid>
                <Grid item xs={12} lg={8}>
                  <Typography
                    variant="h6"
                    color="primary"
                    className={classes.text}
                  >
                    The CTCN promotes the accelerated transfer of
                    environmentally sound technologies for low carbon and
                    climate resilient development at the request of developing
                    countries. CTCN provides technology solutions, capacity
                    building and advice on policy, legal and regulatory
                    frameworks tailored to the needs of individual countries.
                    The CTCN is the operational arm of the United Nation’s
                    Framework Convention on Climate Change (UNFCCC) technology
                    mechanism.
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Box width={1} display="flex" justifyContent="center">
                    <a
                      target="_target"
                      href={"https://www.iucn.org/"}
                      title="IUCN Home page"
                    >
                      <img
                        alt="IUCN Logo"
                        src={iucnLogo}
                        style={{ width: "auto", height: 110, padding: 10 }}
                      />
                    </a>
                  </Box>
                </Grid>
                <Grid item xs={12} lg={8}>
                  <Typography
                    variant="h6"
                    color="primary"
                    className={classes.text}
                  >
                    The International Union for the Conservation of Nature helps
                    the world find pragmatic solutions to the most pressing
                    environment and development challenges. It supports
                    scientific research, manages field projects all over the
                    world and brings governments, non-government organizations,
                    United Nations agencies, companies and local communities
                    together to develop and implement policy, laws and best
                    practice. IUCN supports nature-based solutions.
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Box width={1} display="flex" justifyContent="center">
                    <a
                      target="_target"
                      href={"https://unepdtu.org/"}
                      title="UNEP DTU Home page"
                    >
                      <img
                        alt="UNEP DTU Logo"
                        src={unepDtuLogo}
                        style={{ width: "auto", height: 135, padding: 10 }}
                      />
                    </a>
                  </Box>
                </Grid>
                <Grid item xs={12} lg={8}>
                  <Typography
                    variant="h6"
                    color="primary"
                    className={classes.text}
                  >
                    UNEP Copenhagen Climate Centre is a leading international advisory institution on energy,
                     climate and sustainable development. Its work focuses on assisting developing countries
                      and emerging economies transition towards more low carbon development paths, 
                      and supports integration of climate-resilience in national development. 
                      UNEP Copenhagen Climate Centre is actively engaged in implementing UN Environment’s Climate Change Strategy
                       and Energy Programme.
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Layout.Content>
      </Layout>
    </>
  );
};

export default Partners;
