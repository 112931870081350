import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  intro: {
    marginTop: "1rem",
    marginLeft: "3.5rem",
    marginRight: "3rem",
  },
  introMobile: {
    marginTop: "-4rem",
    marginLeft: "1rem",
    marginRight: "1rem",
  },
  titleIntro: {
    marginBottom: "1rem",
  },
  textIntro: {
    marginBottom: "1rem",
    fontWeight: "normal",
    lineHeight: 1.25,
  },
  divButton: {
    marginTop: "4rem",
    display: "flex",
    justifyContent: "flex-end",
  },
  confirmationButton: {
    width: "auto",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    fontSize: "1.25rem",
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  confirmationButtonMobile: {
    width: "auto",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    fontSize: "0.75rem",
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));
