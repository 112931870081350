import React from "react";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const SDGs = ({ sdgs = [] }) => {
  const getLogoUrl = (logo) => {
    const { url, formats } = logo ? logo : {};
    const { thumbnail } = formats || {};
    const { url: urlThumbnail } = thumbnail || {};
    const useURL = urlThumbnail || url;
    return useURL;
  };

  return (
    <Box>
      <Typography gutterBottom variant="body1" color="textSecondary">
        Related SDGs
      </Typography>
      <Box>
        <Box
          display="flex"
          flexWrap="wrap"
          alignContent="space-between"
          width="100%"
          margin="-0.5rem"
        >
          {sdgs.map(({ name, logo }) => (
            <Box key={name} width="4rem" height="4rem" margin="0.5rem">
              <img
                alt={name}
                src={getLogoUrl(logo)}
                style={{ width: "inherit", height: "inherit" }}
                title={name}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default SDGs;
