import React from "react";

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";

import useStyles from "../../lib/useStyles";
import MainTitle from "../MainTitle";
import SelectChips from "../SelectChips";

const Step1 = ({
  values,
  onChange,
  setFieldValue,
  onBlur,
  errors = {},
  getHelperText,
  offices,
  countries,
  mobileView,
}) => {
  const classes = useStyles();

  return (
    <Box>
      <MainTitle />
      <Typography
        variant="h6"
        color="primary"
        className={mobileView ? classes.subTitleMobile : classes.subTitle}
      >
        Introduce the company
      </Typography>
      <Box className={mobileView ? classes.fieldsMobile : classes.fields}>
        <TextField
          name="step1.companyName"
          label="Company name"
          value={values.companyName}
          onChange={onChange}
          onBlur={onBlur}
          className={classes.textField}
          fullWidth
          required
          helperText={getHelperText(
            errors,
            "companyName",
            "The name of the company that you want to add"
          )}
          FormHelperTextProps={{
            className: errors["companyName"]
              ? classes.error
              : classes.helperText,
          }}
        />
        <TextField
          name="step1.companyDescription"
          label="Company description"
          value={values.companyDescription}
          onChange={onChange}
          onBlur={onBlur}
          className={classes.textField}
          fullWidth
          required
          multiline
          rowsMax={4}
          helperText={getHelperText(
            errors,
            "companyDescription",
            "Concise and short intro"
          )}
          FormHelperTextProps={{
            className: errors["companyDescription"]
              ? classes.error
              : classes.helperText,
          }}
        />
        <TextField
          name="step1.linkToHomepage"
          label="Link to your homepage"
          value={values.linkToHomepage}
          onChange={onChange}
          onBlur={onBlur}
          className={classes.textField}
          fullWidth
          required
          helperText={getHelperText(
            errors,
            "linkToHomepage",
            "Provide a link to company website"
          )}
          FormHelperTextProps={{
            className: errors["linkToHomepage"]
              ? classes.error
              : classes.helperText,
          }}
        />
        <TextField
          name="step1.linkToSocials"
          label="Link to your LinkedIn or other social media"
          value={values.linkToSocials}
          onChange={onChange}
          onBlur={onBlur}
          className={classes.textField}
          fullWidth
          helperText={getHelperText(
            errors,
            "linkToSocials",
            "If your company has a social media profile you can add it here"
          )}
          FormHelperTextProps={{
            className: errors["linkToSocials"]
              ? classes.error
              : classes.helperText,
          }}
        />
      </Box>
      <Typography
        variant="h6"
        color="primary"
        className={
          mobileView ? classes.subTitleMiddleMobile : classes.subTitleMiddle
        }
      >
        Where the companies and their projects are located
      </Typography>
      <Box className={mobileView ? classes.fieldsMobile : classes.fields}>
        <FormControl className={classes.formControl}>
          <InputLabel className={classes.inputLabel}>
            Location of main office *
          </InputLabel>
          <SelectChips
            name="step1.location"
            values={values.location}
            setFieldValue={setFieldValue}
            required
            options={offices}
          />
          <FormHelperText
            className={errors["location"] ? classes.error : classes.helperText}
          >
            {getHelperText(
              errors,
              "location",
              "In which country is the company’s main office located?"
            )}
          </FormHelperText>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel className={classes.inputLabel}>
            Location of units or satellite offices
          </InputLabel>
          <SelectChips
            name="step1.otherLocations"
            values={values.otherLocations}
            setFieldValue={setFieldValue}
            multiple
            options={offices}
          />
          <FormHelperText
            className={
              errors["otherLocations"] ? classes.error : classes.helperText
            }
          >
            {getHelperText(
              errors,
              "otherLocations",
              "If the company has offices in other locations than already mentioned, please add in which countries."
            )}
          </FormHelperText>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel className={classes.inputLabel}>
            Location of projects done by the company *
          </InputLabel>
          <SelectChips
            name="step1.locationsByCompany"
            values={values.locationsByCompany}
            setFieldValue={setFieldValue}
            required
            multiple
            options={countries}
          />
          <FormHelperText
            className={
              errors["locationsByCompany"] ? classes.error : classes.helperText
            }
          >
            {getHelperText(
              errors,
              "locationsByCompany",
              "Countries where the company has done projects or solutions"
            )}
          </FormHelperText>
        </FormControl>
      </Box>
      <Typography
        variant="h6"
        color="primary"
        className={mobileView ? classes.subTitleMobile : classes.subTitle}
      >
        Size of the company *
      </Typography>
      <Box className={mobileView ? classes.fieldsMobile : classes.fields}>
        <FormControl className={classes.formControl}>
          <RadioGroup
            name="step1.companySize"
            value={values.companySize}
            onChange={onChange}
          >
            <FormControlLabel
              className={classes.radioItem}
              value="small"
              control={<Radio color="primary" />}
              label="Small (1-50 employees)"
            />
            <FormControlLabel
              className={classes.radioItem}
              value="medium"
              control={<Radio color="primary" />}
              label="Medium (50-250 employees)"
            />
            <FormControlLabel
              className={classes.radioItem}
              value="large"
              control={<Radio color="primary" />}
              label="Large (250+ employees)"
            />
          </RadioGroup>
          <FormHelperText
            className={
              errors["companySize"] ? classes.error : classes.helperText
            }
          >
            {getHelperText(errors, "companySize", "")}
          </FormHelperText>
        </FormControl>
      </Box>
    </Box>
  );
};

export default Step1;
