import React, { useState, useEffect } from "react";
import { useHistory, Link as RouterLink } from "react-router-dom";
import clsx from "clsx";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MenuIcon from "@material-ui/icons/Menu";
import Divider from "@material-ui/core/Divider";

import NavButtons from "components/NavButtons";
import UserAvatar from "components/NavButtons/UserAvatar";
import useStylesNavButtons from "components/NavButtons/lib/useStyles";
import AlertDialog from "components/AlertDialog";

import { logout } from "queries/auth";

import portalLogoDark from "assets/portalLogoDark.svg";
import portalLogoLight from "assets/portalLogoLight.svg";
import portalLogoSmall from "assets/logoSmall.svg";
import portalLogoSdgDark from "assets/logoSdgDark.svg";
import portalLogoSdgLight from "assets/logoSdgLight.png";

import useStyles from "./lib/useStyles";

const Header = ({ listButtons, appBarColor }) => {
  const history = useHistory();
  const classes = useStyles();
  const classesNavButtons = useStylesNavButtons();

  const [mobileView, setMobileView] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleResponse = (answer) => {
    if (answer) {
      logout();
      history.push("/");
    }
  };

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1059
        ? setMobileView(true)
        : setMobileView(false);
    };

    setResponsiveness();

    const onResize = () => setResponsiveness();

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  const displayDesktop = () => {
    return (
      <Toolbar disableGutters>
        <Box className={classes.container}>
          <Box
            className={classes.block}
            justifyContent="flex-start"
            style={{ marginLeft: "5rem" }}
          >
            <Box onClick={() => history.push("/")} className={classes.link}>
              <img
                alt="Logo"
                src={
                  appBarColor === "primary" ? portalLogoLight : portalLogoDark
                }
              />
            </Box>
            <Divider
              orientation="vertical"
              flexItem
              className={
                appBarColor === "primary"
                  ? classes.dividerLight
                  : classes.dividerDark
              }
            />
            <Box>
              <a
                target="_target"
                href={"https://sdgs.un.org/goals"}
                title="Sustainable Development Goals"
              >
                <img
                  alt="LogoSdg"
                  src={
                    appBarColor === "primary"
                      ? portalLogoSdgLight
                      : portalLogoSdgDark
                  }
                  style={{ width: "auto", height: 40 }}
                />
              </a>
            </Box>
          </Box>
          <Box
            className={classes.block}
            justifyContent="flex-end"
            style={{ marginRight: "5rem" }}
          >
            <NavButtons list={listButtons} appBarColor={appBarColor} />
          </Box>
        </Box>
      </Toolbar>
    );
  };

  const displayMobile = () => {
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <Toolbar disableGutters>
        <Box className={classes.container}>
          <Box className={classes.left} justifyContent="flex-start">
            <Box onClick={() => history.push("/")} className={classes.link}>
              <img alt="Logo" src={portalLogoSmall} />
            </Box>
            <Divider
              orientation="vertical"
              flexItem
              className={
                appBarColor === "primary"
                  ? classes.dividerLight
                  : classes.dividerDark
              }
            />
            <Box>
              <img
                alt="LogoSdg"
                src={
                  appBarColor === "primary"
                    ? portalLogoSdgLight
                    : portalLogoSdgDark
                }
                style={{ width: "auto", height: 40 }}
              />
            </Box>
          </Box>
          <Box className={classes.right} justifyContent="flex-end">
            <Box marginRight="1rem">
              <IconButton
                {...{
                  edge: "start",
                  // color: "inherit",
                  "aria-label": "menu",
                  "aria-haspopup": "true",
                  onClick: handleClick,
                }}
                className={clsx(
                  classesNavButtons.button,
                  {
                    [classesNavButtons.labelWhite]: appBarColor === "primary",
                  },
                  {
                    [classesNavButtons.labelBlue]: appBarColor !== "primary",
                  },
                  {
                    [classesNavButtons.labelWhiteIsNotActive]:
                      appBarColor === "primary",
                  },
                  {
                    [classesNavButtons.labelBlueIsNotActive]:
                      appBarColor !== "primary",
                  }
                )}
              >
                <MenuIcon />
              </IconButton>

              <Menu
                id="menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {getMenuChoices()}
              </Menu>
            </Box>
            <Box marginRight="1rem">
              {localStorage.getItem("token") &&
              localStorage.getItem("token") !== "undefined" ? (
                <UserAvatar
                  setOpenAlert={setOpenAlert}
                  classes={classesNavButtons}
                  appBarColor={appBarColor}
                />
              ) : (
                ""
              )}
            </Box>
          </Box>
        </Box>
      </Toolbar>
    );
  };

  const getMenuChoices = () => {
    const listMenuButtons = [];
    if (
      localStorage.getItem("token") &&
      localStorage.getItem("token") !== "undefined" &&
      localStorage.getItem("userProviderId") &&
      localStorage.getItem("userProviderId") !== "-1"
    ) {
      listMenuButtons.push({
        label: "Edit solution provider",
        href: "/add-company",
      });
    } else {
      listMenuButtons.push({
        label: "Add solution provider",
        href: "/add-company-intro",
      });
    }
    listMenuButtons.push({
      label: "Goal",
      href: "/about",
    });
    listMenuButtons.push({
      label: "Partners",
      href: "/partners",
    });
    listMenuButtons.push({
      label: "Contact us",
      href: "/contact-us",
    });
    return listMenuButtons.map(({ label, href }) => {
      return (
        <Link
          {...{
            component: RouterLink,
            to: href,
            color: "inherit",
            style: { textDecoration: "none" },
            key: label,
          }}
        >
          <MenuItem>{label}</MenuItem>
          {href === "/add-company" || href === "/add-company-intro" ? (
            <Divider />
          ) : (
            ""
          )}
        </Link>
      );
    });
  };

  return (
    <>
      <AppBar color={appBarColor}>
        {mobileView ? displayMobile() : displayDesktop()}
      </AppBar>
      {openAlert && (
        <AlertDialog
          open={openAlert}
          close={() => setOpenAlert(false)}
          handleResponse={(response) => handleResponse(response)}
          title={"Log out"}
          message={"Do you want to log out?"}
          maxWidth="sm"
          cancelButton
        />
      )}
    </>
  );
};

export default Header;
