import { createStyles, makeStyles } from "@material-ui/core/styles";

const inheritHeight = {
  minHeight: "inherit",
  margin: 0,
  padding: 0,
};

export default makeStyles((theme) =>
  createStyles({
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      marginTop: "4px",
      overflow: "hidden",
      ...inheritHeight,
    },
    block: {
      width: "calc(100% / 2)",
      height: "inherit",
      display: "flex",
      alignItems: "center",
      ...inheritHeight,
    },
    left: {
      width: "calc(100% / 2)",
      display: "flex",
      marginLeft: "2rem",
    },
    right: {
      width: "calc(100% / 2)",
      display: "flex",
      marginRight: "1rem",
    },
    dividerLight: {
      marginLeft: "1rem",
      marginRight: "1rem",
      backgroundColor: theme.palette.primary.contrastText,
    },
    dividerDark: {
      marginLeft: "1rem",
      marginRight: "1rem",
      backgroundColor: "#231F20",
    },
    link: {
      cursor: "pointer",
    },
  })
);
