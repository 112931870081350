import React from "react";
import { useQuery } from "@apollo/client";
import { useRecoilState } from "recoil";
import debounce from "lodash.debounce";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";

import filterState from "../../states/filterState";
import { GET_COUNTRIES } from "queries/countries";
import { GET_CHALLENGES } from "queries/challenges";

import Select from "components/Select";
import Field from "components/Field";

import challenge from "assets/iconChallenge.svg";
import solution from "assets/iconSolutions.svg";
import location from "assets/iconLocation.svg";

const ChallengeIcon = () => <img src={challenge} alt="challenge" />;

const SolutionIcon = () => <img src={solution} alt="solution" />;

const LocationIcon = () => <img src={location} alt="location" />;

const SearchBar = () => {
  const [filter, setFilter] = useRecoilState(filterState);

  const { data: dataChallenges = {}, loading: loadingChallenges } =
    useQuery(GET_CHALLENGES);
  const { challenges = [] } = dataChallenges;

  const { data: dataCountries = {}, loading: loadingCountries } =
    useQuery(GET_COUNTRIES);
  const { countries = [] } = dataCountries;

  return (
    <Box marginY="1.5rem" textAlign="left">
      {loadingChallenges || loadingCountries ? (
        ""
      ) : (
        <Grid container justify="center">
          <Grid item xs={8} sm={8} md={8} lg={2}>
            <Paper style={{ display: "flex" }} elevation={3}>
              <Select
                options={challenges.map(({ displayName, name }) => ({
                  label: displayName,
                  value: name,
                }))}
                defaultValue={filter.challenge}
                defaultText="Select challenge"
                title="Select challenge"
                onChange={(value) => setFilter({ ...filter, challenge: value })}
                icon={ChallengeIcon}
                fullWidth
              />
            </Paper>
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid item xs={8} sm={8} md={8} lg={2}>
            <Paper style={{ display: "flex" }} elevation={3}>
              <Field
                fullWidth
                placeholder="Search for a solution"
                title="Search for a solution"
                defaultValue={filter.techSolution}
                onChange={debounce(
                  (value) => setFilter({ ...filter, techSolution: value }),
                  1000
                )}
                icon={SolutionIcon}
              />
            </Paper>
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid item xs={8} sm={8} md={8} lg={2}>
            <Paper style={{ display: "flex" }} elevation={3}>
              <Select
                options={countries.map(({ id, name }) => ({
                  label: name,
                  value: id,
                }))}
                defaultValue={filter.location}
                defaultText="Select location"
                title="Select location"
                onChange={(value) => setFilter({ ...filter, location: value })}
                icon={LocationIcon}
                fullWidth
              />
            </Paper>
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid item xs={8} sm={8} md={8} lg={2}>
            <Paper style={{ display: "flex" }} elevation={3}>
              <Field
                fullWidth
                placeholder="Search for a provider"
                title="Search for a provider"
                defaultValue={filter.provider}
                onChange={debounce(
                  (value) => setFilter({ ...filter, provider: value }),
                  1000
                )}
                icon={SolutionIcon}
              />
            </Paper>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default SearchBar;
