import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  providersList: {
    paddingTop: theme.spacing(2),
  },
  loadingImageContainer: {
    width: "100%",
    height: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  alertButton: {
    alignSelf: "baseline",
    marginLeft: "5px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  alertButtonSecondary: {
    color: theme.palette.darkGrey.main,
  },
  imageBox: {
    position: "relative",
    backgroundColor: theme.palette.secondary.contrastText,
    overflow: "hidden",
    textAlign: "center",
  },
}));
