import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  paper: {
    overflow: "auto",
    width: "auto",
    height: "auto",
    padding: theme.spacing(2),
  },
  dialogContent: {
    backgroundColor: theme.palette.lightGrey.main,
    minHeight: "1rem",
  },
  titleText: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    fontSize: "1.2rem",
  },
  messageText: {
    color: theme.palette.primary.main,
    overflow: "hidden",
  },
  confirmationButton: {
    width: "156px",
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  cancelButton: {
    color: theme.ACTIONBLUE_DEFAULT,
    marginRight: "24px",
    textTransform: "none",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  divider: {
    height: "2px",
    marginBottom: "2rem",
  },
}));
