import { gql } from "@apollo/client";

export const GET_SOLUTIONS_BY_FILTER = gql`
  query solutionsByFilter(
    $start: Int!
    $limit: Int!
    $challenge_name: String
    $country_id: Int
    $name_contains: String
    $provider_name_contains: String
  ) {
    solutionsByFilter(
      sort: "name:asc"
      limit: $limit
      start: $start
      challenge_name: $challenge_name
      country_id: $country_id
      name_contains: $name_contains
      provider_name_contains: $provider_name_contains
    ) {
      id
      name
      media {
        formats
        url
      }
      challenges(sort: "displayName:asc") {
        displayName
      }
      green_solution
    }
  }
`;

export const GET_SOLUTION = gql`
  query solution($where: JSON) {
    solutions(where: $where) {
      name
      description
      description_short
      cost_max
      cost_min
      maturity_max
      maturity_min
      time_min
      time_max
      investment_min
      investment_max
      green_solution
      media {
        formats
        url
      }
      sdgs(sort: "sdg_id:asc") {
        name
        logo {
          formats
          url
        }
      }
      challenges(sort: "displayName:asc") {
        displayName
      }
    }
  }
`;
