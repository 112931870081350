import React from "react";
import Typography from "@material-ui/core/Typography";
import useStyles from "../../lib/useStyles";

const MainTitle = () => {
  const classes = useStyles();

  let text = "Add your company as a Solution Provider";

  if (
    localStorage.getItem("token") &&
    localStorage.getItem("token") !== "undefined" &&
    localStorage.getItem("userProviderId") &&
    localStorage.getItem("userProviderId") !== "-1"
  ) {
    text = "Edit your company as a Solution Provider";
  }

  return (
    <Typography variant="h4" color="primary" className={classes.mainTitle}>
      {text}
    </Typography>
  );
};

export default MainTitle;
