import React from "react";

import Header from "./partials/Header";
import Content from "./partials/Content";
import Footer from "./partials/Footer";

const Layout = ({
  children,
  listButtons = [],
  appBarColor,
  withFooter = true,
}) => {
  return (
    <>
      <Header listButtons={listButtons} appBarColor={appBarColor} />
      {children}
      {withFooter && <Footer />}
    </>
  );
};

Layout.Content = Content;

export default Layout;
