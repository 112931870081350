import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import { GET_PROVIDERS_PUBLISHED_COUNT } from "queries/counts";
import SearchBar from "../SearchBar";
import useStyles from "./lib/useStyles";
import logoSolutions from "assets/logoSolutions.svg";
import logoSolutionsSmall from "assets/logoSolutionsSmall.svg";

const HeroHome = () => {
  const classes = useStyles();

  const { data: dataProviders = {} } = useQuery(GET_PROVIDERS_PUBLISHED_COUNT);
  const { providersConnection = { aggregate: { count: 0 } } } = dataProviders;

  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 955
        ? setMobileView(true)
        : setMobileView(false);
    };

    setResponsiveness();

    const onResize = () => setResponsiveness();

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return (
    <Box classes={{ root: classes.hero }}>
      <Box display="flex" alignItems="center" overflow="hidden">
        <Box classes={{ root: classes.heroContent }}>
          <Typography variant="h1" className={classes.heroText}>
            MATCH!
          </Typography>
          <img
            alt="Logo"
            src={mobileView ? logoSolutionsSmall : logoSolutions}
            style={{ width: "auto", height: "auto" }}
          />
          <Typography gutterBottom variant="h4" className={classes.heroText}>
            {providersConnection.aggregate.count} technology solution providers
          </Typography>
          <Typography
            gutterBottom
            variant="h4"
            className={classes.heroText}
            style={{ fontWeight: "normal" }}
          >
            looking to solve your water challenges
          </Typography>
          <SearchBar />
        </Box>
      </Box>
    </Box>
  );
};

export default HeroHome;
