import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

const Modal = ({ isOpen, onClose, children, maxWidth = "md" }) => {
  return (
    isOpen && (
      <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth={maxWidth}>
        {children}
      </Dialog>
    )
  );
};

Modal.Header = DialogTitle;
Modal.Content = DialogContent;
Modal.Footer = DialogActions;

export default Modal;
