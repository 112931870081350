import React from "react";
import { Button } from "@material-ui/core";
import useStyles from "./lib/useStyles";

const Nav = (prop) => {
  const {
    activeStep,
    stepsNumber,
    nextDisabled,
    onNext,
    onBack,
    onFinish,
  } = prop;
  const classes = useStyles();

  return (
    <div className={classes.buttonsRow}>
      <Button
        onClick={onBack}
        className={classes.backButton}
        title={activeStep === 0 ? "Cancel" : "Go to previous step"}
      >
        {activeStep === 0 ? "Cancel" : "Back"}
      </Button>
      <Button
        variant="contained"
        color="primary"
        disabled={nextDisabled}
        onClick={activeStep === stepsNumber - 1 ? onFinish : onNext}
        title={activeStep === stepsNumber - 1 ? "Submit" : "Go to next step"}
        className={
          nextDisabled ? classes.nextButtonDisabled : classes.nextButton
        }
      >
        {activeStep === stepsNumber - 1 ? "Submit" : "Next"}
      </Button>
    </div>
  );
};

export default Nav;
