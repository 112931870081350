import React, { useState, useEffect, useMemo } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";

import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import CircularProgress from "@material-ui/core/CircularProgress";

import Layout from "components/Layout";
import AddCompanyHero from "./partials/AddCompanyHero";
import Nav from "./partials/Nav";
import Step1 from "./partials/Step1";
import Step2 from "./partials/Step2";
import Step3 from "./partials/Step3";
import Step4 from "./partials/Step4";
import AlertDialog from "components/AlertDialog";

import { GET_OFFICES } from "queries/offices";
import { GET_COUNTRIES } from "queries/countries";
import {
  CREATE_PROVIDER,
  GET_NEW_PROVIDER,
  UPDATE_PROVIDER,
} from "queries/providers";
import { UPDATE_USER } from "queries/user";

import { getHelperText, removeRecaptcha } from "config/utils";

import useStyles from "./lib/useStyles";

import {
  setDefaultValuesForEachStep,
  setCompanyValuesForEachStep,
  validateStep,
  steps,
  handleGoNext,
  handleGoBack,
  getListButtons,
} from "./partials/utils";

const AddCompany = () => {
  const [activeStep, setActiveStep] = useState(0);
  const classes = useStyles();
  const history = useHistory();
  const [loadedCompany, setLoadedCompany] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [action, setAction] = useState("add");
  const userProviderId = localStorage.getItem("userProviderId");

  removeRecaptcha();

  useMemo(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      history.push("/login");
    }
  }, [history]);

  const formik = useFormik({
    initialValues: setDefaultValuesForEachStep(),
    validate: (values) => ({
      step1: validateStep(1, values),
      step2: validateStep(2, values),
      step3: validateStep(3, values),
    }),
  });

  const { loadingProvider, data: dataProvider } = useQuery(GET_NEW_PROVIDER, {
    variables: {
      id: userProviderId,
    },
    skip: !userProviderId,
    fetchPolicy: "no-cache",
  });

  if (
    localStorage.getItem("token") &&
    localStorage.getItem("token") !== "undefined" &&
    !loadedCompany
  ) {
    if (
      userProviderId &&
      userProviderId !== -1 &&
      dataProvider &&
      !loadingProvider
    ) {
      formik.setValues(
        setCompanyValuesForEachStep(dataProvider.provider, setLoadedCompany)
      );
    }
  }

  const [updateProvider] = useMutation(UPDATE_PROVIDER);
  const [createProvider] = useMutation(CREATE_PROVIDER);
  const [updateUser] = useMutation(UPDATE_USER);

  const saveCompany = async (formik, action, providerId) => {
    const solutionIds = formik.values.step2.solutions.map(
      (solution) => solution.id
    );
    let body = {
      name: formik.values.step1.companyName,
      description: formik.values.step1.companyDescription,
      url: formik.values.step1.linkToHomepage,
      url_social_media: formik.values.step1.linkToSocials,
      main_office: formik.values.step1.location[0],
      offices: formik.values.step1.otherLocations,
      countries: formik.values.step1.locationsByCompany,
      size: formik.values.step1.companySize,
      solutions: solutionIds,
      media: formik.values.step3.companyLogo.id,
      published_at: null,
    };
    if (action === "edit") {
      updateProvider({
        variables: {
          id: parseInt(providerId),
          body,
        },
      })
        .then((result) => {
          console.log("provider updated");
          return result;
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      return await createProvider({ variables: { body } })
        .then(async (result) => {
          console.log("provider created");
          const newId = await result.data.createProvider.provider.id;
          return await updateUser({
            variables: {
              id: parseInt(localStorage.getItem("userId")),
              providerId: newId,
            },
          }).then(() => {
            console.log("user updated with the new provider created");
            localStorage.setItem("userProviderId", newId);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleFinish = async () => {
    formik.handleSubmit();
    if (userProviderId && userProviderId !== -1) {
      setAction("edit");
    }
    await saveCompany(formik, action, userProviderId);
    setOpenAlert(true);
  };

  const handleResponse = (answer) => {
    if (answer) {
      history.push("/");
    }
  };

  // state props for displaying dialog confirm
  const [openConfirm, setOpenConfirm] = useState(false);
  const handleClickOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const listButtons = getListButtons(loadedCompany);

  let offices = [];
  const { data: dataOffices = {} } = useQuery(GET_OFFICES, {
    variables: { start: 1, limit: 100 },
  });
  offices = offices.concat(dataOffices.offices);
  const { data: dataOffices2 = {} } = useQuery(GET_OFFICES, {
    variables: { start: 101, limit: 100 },
  });
  offices = offices.concat(dataOffices2.offices);
  const { data: dataOffices3 = {} } = useQuery(GET_OFFICES, {
    variables: { start: 201, limit: 100 },
  });
  offices = offices.concat(dataOffices3.offices);
  offices = offices.filter((office) => office !== undefined);

  // const { data: dataOffices = {} } = useQuery(GET_OFFICES, {
  //   variables: { start: 1, limit: 250 },
  // });
  // const { offices = [] } = dataOffices;

  const { data: dataCountries = {} } = useQuery(GET_COUNTRIES);
  const { countries = [] } = dataCountries;

  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 820
        ? setMobileView(true)
        : setMobileView(false);
    };

    setResponsiveness();

    const onResize = () => setResponsiveness();

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return (
    <>
      <Layout listButtons={listButtons} appBarColor="secondary">
        <AddCompanyHero />
        <Layout.Content>
          <Box
            className={
              mobileView ? classes.mainContentMobile : classes.mainContent
            }
          >
            <Stepper activeStep={activeStep}>
              {steps.map(({ title }) => (
                <Step key={title}>
                  <StepLabel>{mobileView ? "" : title}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Divider className={classes.divider} />
            {activeStep < steps.length && (
              <form className={classes.form}>
                {userProviderId && userProviderId !== -1 && !loadedCompany ? (
                  <CircularProgress />
                ) : (
                  <>
                    {activeStep === 0 && (
                      <Step1
                        values={formik.values.step1}
                        onChange={formik.handleChange}
                        setFieldValue={formik.setFieldValue}
                        onBlur={formik.handleBlur}
                        errors={formik.errors.step1}
                        getHelperText={getHelperText}
                        offices={offices}
                        countries={countries}
                        mobileView={mobileView}
                      />
                    )}
                    {activeStep === 1 && (
                      <Step2
                        values={formik.values.step2}
                        setFieldValue={formik.setFieldValue}
                        errors={formik.errors.step2}
                        getHelperText={getHelperText}
                      />
                    )}
                    {activeStep === 2 && (
                      <Step3
                        values={formik.values.step3}
                        setFieldValue={formik.setFieldValue}
                        onBlur={formik.handleBlur}
                        errors={formik.errors.step3}
                        getHelperText={getHelperText}
                        mobileView={mobileView}
                      />
                    )}
                    {activeStep === 3 && (
                      <Step4
                        values={formik.values}
                        offices={offices}
                        countries={countries}
                        mobileView={mobileView}
                      />
                    )}
                  </>
                )}
              </form>
            )}
            <Divider className={classes.divider} />
            <Nav
              activeStep={activeStep}
              stepsNumber={steps.length}
              nextDisabled={
                (validateStep(activeStep + 1, formik.values) &&
                  validateStep(activeStep + 1, formik.values).length !== 0) ||
                formik.submitCount > 0
              }
              onNext={() => handleGoNext(activeStep, setActiveStep)}
              onBack={() =>
                activeStep > 0
                  ? handleGoBack(activeStep, setActiveStep)
                  : handleClickOpenConfirm()
              }
              onFinish={handleFinish}
            />
          </Box>
        </Layout.Content>
      </Layout>
      {openAlert && (
        <AlertDialog
          open={openAlert}
          close={() => setOpenAlert(false)}
          handleResponse={(response) => handleResponse(response)}
          title={"Thank you for your contribution"}
          message={
            action === "edit"
              ? "Your company profile has been updated."
              : "The UNEP-DHI Centre will check and publish your company profile on the Match! Water Solutions Portal within a week.\nYou will receive a notification email when your company profile has been added to the Match! Portal."
          }
          maxWidth="sm"
        />
      )}
      {openConfirm && (
        <AlertDialog
          open={openConfirm}
          close={() => setOpenConfirm(false)}
          handleResponse={(response) => handleResponse(response)}
          title={"You’re ending the process in advance"}
          message={
            "If you close the dialog the changes you have done will not be saved.\n Are you sure you want to continue?"
          }
        />
      )}
    </>
  );
};

export default AddCompany;
