import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "25rem",
  },
  containerMobile: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontWeight: "bold",
    marginBottom: "0.5rem",
  },
  stepContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "4rem",
  },
  circleContainer: {
    position: "relative",
    width: "1.5rem",
    height: "1.5rem",
  },
  circle: {
    position: "absolute",
    zIndex: 1,
    width: "1rem",
    height: "1rem",
    borderRadius: "100%",
    backgroundColor: theme.palette.mediumGrey.main,
    display: "flex",
    boxSizing: "content-box",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "0.25rem",
    fontSize: "0.75rem",
    color: theme.palette.primary.contrastText,
    lineHeight: "0.75rem",
    fontWeight: "bold",
  },
  circleIsActive: {
    backgroundColor: "#F1692A",
  },
  count: {
    position: "relative",
    zIndex: 1,
  },
  line: {
    position: "absolute",
    width: "100%",
    height: "2px",
    zIndex: 0,
    top: "50%",
  },
  lineIsActive: {
    width: "4rem",
    backgroundColor: "#F1692A",
    marginLeft: "-4rem",
  },
  lineIsNotActive: {
    width: "4rem",
    backgroundColor: theme.palette.mediumGrey.main,
    marginLeft: "-4rem",
  },
  lineIsNotInRange: {
    width: "0rem",
  },
  labelContainer: {
    display: "flex",
    justifyContent: "center",
    height: "1rem",
    marginTop: "0.25rem",
  },
  label: {
    fontSize: "0.85rem",
    color: "#86A2B3",
  },
  labelIsActive: {
    color: "#F1692A",
    fontWeight: "bold",
  },
}));
