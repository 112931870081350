import { makeStyles } from "@material-ui/core/styles";
import heroBackground from "assets/heroAddCompany.png";

export default makeStyles((theme) => ({
  hero: {
    backgroundImage: `url(${heroBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "100%",
    minHeight: "580px",
  },
  heroContent: {
    marginTop: "8rem",
    marginBottom: "3rem",
    marginLeft: "3.5rem",
    width: "100%",
  },
  heroText: {
    color: theme.palette.primary.contrastText,
  },
}));
