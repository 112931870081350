import React from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import useStyles from "./lib/useStyles";

const HeroAbout = () => {
  const classes = useStyles();

  return (
    <Box classes={{ root: classes.hero }}>
      <Container maxWidth="lg">
        <Box display="flex" overflow="hidden">
          <Box classes={{ root: classes.heroContent }}>
            <Typography variant="h4" className={classes.heroText} gutterBottom>
              Goal
            </Typography>
            <Typography
              gutterBottom
              variant="h3"
              className={classes.heroText}
              style={{ fontWeight: "normal" }}
            >
              The purpose of the Match! Portal is to bring
              <br />
              together practitioners with water challenges and
              <br />
              solution providers.
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default HeroAbout;
