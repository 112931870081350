export const getListButtons = () => {
  const list = [
    {
      label: "Goal",
      href: "/about",
      isActive: true,
    },
  ];

  if (
    localStorage.getItem("userProviderId") &&
    localStorage.getItem("userProviderId") !== "-1"
  ) {
    list.push({
      label: "Edit solution provider",
      href: "/add-company",
      isButton: true,
      isNotActive: true,
    });
  } else {
    list.push({
      label: "Add solution provider",
      href: "/add-company-intro",
      isButton: true,
      isNotActive: true,
    });
  }
  return list;
};
