import { createStyles, makeStyles, fade } from "@material-ui/core/styles";

export default makeStyles((theme) =>
  createStyles({
    link: {
      cursor: "pointer",
      textDecoration: "none",
      paddingRight: "2rem",
      "&:hover": {
        textDecoration: "none",
      },
      "&:active": {
        textDecoration: "none",
      },
      "&:focus": {
        textDecoration: "none",
      },
    },
    menuItem: {
      color: "#183668",
      cursor: "pointer",
      textDecoration: "none",
      "&:hover": {
        textDecoration: "none",
      },
      "&:active": {
        textDecoration: "none",
      },
      "&:focus": {
        textDecoration: "none",
      },
    },
    labelWhite: {
      color: theme.palette.primary.contrastText,
      fontSize: "1.25rem",
    },
    labelBlue: {
      color: "#183668",
      fontSize: "1.25rem",
    },
    labelIsActive: {
      fontWeight: "bold",
    },
    labelBlueIsNotActive: {
      color: "#183668",
      "&:hover": {
        color: fade("#183668", 0.6),
      },
    },
    labelWhiteIsNotActive: {
      color: theme.palette.primary.contrastText,
      "&:hover": {
        color: fade(theme.palette.primary.contrastText, 0.6),
      },
    },
    button: {
      backgroundColor: "transparent",
      paddingLeft: "1rem",
      paddingRight: "1rem",
      maxHeight: "40px",
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    buttonWhite: {
      border: "1px solid " + theme.palette.primary.contrastText,
    },
    buttonWhiteIsNotActive: {
      border: "1px solid" + theme.palette.primary.contrastText,
      "&:hover": {
        borderColor: fade(theme.palette.primary.contrastText, 0.6),
      },
    },
    buttonBlue: {
      border: "1px solid #183668",
    },
    buttonBlueIsNotActive: {
      border: "1px solid #183668",
      "&:hover": {
        borderColor: fade("#183668", 0.6),
      },
    },
    userAvatar: {
      fontSize: "1.25rem",
      fontWeight: "bold",
    },
  })
);
