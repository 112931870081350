import React, { useState } from "react";

import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";

import Layout from "components/Layout";
import AlertDialog from "components/AlertDialog";

import { resetPassword } from "queries/auth";
import { removeRecaptcha } from "config/utils";

import useStyles from "../lib/useStyles";
import { listButtons } from "../utilsAuthentication";

const ForgotPassword = () => {
  const classes = useStyles();

  const [openAlert, setOpenAlert] = useState(false);

  const [data, setData] = useState({ password: "", passwordConfirmation: "" });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});

  const urlParams = new URLSearchParams(document.location.search);
  const code = urlParams.has("code") ? urlParams.get("code") : "";

  const submit = (e) => {
    e.preventDefault();
    setLoading(true);
    setError({});

    if (data.password !== data.passwordConfirmation) {
      setError("The password doesn't match with the password confirmation");
      setLoading(false);
      return;
    }

    resetPassword(data.password, data.passwordConfirmation, code)
      .then((res) => {
        setLoading(false);
        setOpenAlert(true);
        setData({ password: "", passwordConfirmation: "" });
      })
      .catch((error) => {
        error.data ? setError(error.data) : setError(error);
        setLoading(false);
      });
  };

  removeRecaptcha();

  return (
    <>
      <Layout listButtons={listButtons} appBarColor="transparent">
        <Layout.Content>
          <Box className={classes.mainBox}>
            <Container maxWidth="lg" className={classes.container}>
              <Box className={classes.innerBox}>
                <section className={classes.wrapper}>
                  <Typography
                    variant="h4"
                    color="primary"
                    style={{ fontWeight: "normal" }}
                  >
                    Reset your password
                  </Typography>
                  <Box marginTop="2rem" marginBottom="2rem">
                    <Divider className={classes.divider} />
                  </Box>
                  {Object.entries(error).length !== 0 &&
                    error.constructor === Object &&
                    error.message.map((error) => {
                      return (
                        <div
                          key={error.messages[0].id}
                          style={{ marginBottom: 10 }}
                        >
                          <small style={{ color: "red" }}>
                            {error.messages[0].message}
                          </small>
                        </div>
                      );
                    })}
                  <Box marginTop="2rem">
                    <TextField
                      name="password"
                      label="Password"
                      fullWidth
                      required
                      type="password"
                      onChange={(e) =>
                        setData({ ...data, password: e.target.value })
                      }
                      value={data.password}
                    />
                    <TextField
                      name="passwordConfirmation"
                      label="Password confirmation"
                      fullWidth
                      required
                      type="password"
                      onChange={(e) =>
                        setData({
                          ...data,
                          passwordConfirmation: e.target.value,
                        })
                      }
                      value={data.passwordConfirmation}
                    />
                  </Box>
                  <Box marginTop="4rem" marginBottom="2rem">
                    <Button
                      className={classes.confirmationButton}
                      onClick={submit}
                      title="Reset"
                      disabled={loading}
                    >
                      Reset
                    </Button>
                  </Box>
                </section>
              </Box>
            </Container>
          </Box>
        </Layout.Content>
      </Layout>
      {openAlert && (
        <AlertDialog
          open={openAlert}
          close={() => setOpenAlert(false)}
          title={"Reset password"}
          message={"Your password has been reset."}
          maxWidth="sm"
        />
      )}
    </>
  );
};

export default ForgotPassword;
