import { gql } from "@apollo/client";

export const GET_OFFICES = gql`
  query offices($start: Int!, $limit: Int!) {
    offices(sort: "name:asc", limit: $limit, start: $start) {
      id
      name
    }
  }
`;

export const GET_OFFICES_COUNT = gql`
  {
    officesConnection {
      aggregate {
        count
      }
    }
  }
`;
