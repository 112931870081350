import ReactGA from "react-ga4";

const RECAPTCHA_PUBLIC_KEY = process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY;

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const isValidUrlAllProtocols = (urlString) => {
  let url;
  try {
    url = new URL(urlString);
  } catch (e) {
    return false;
  }
  return url.protocol === "https:" || url.protocol === "http:";
};

export const isValidUrl = (urlString) => {
  let url;
  try {
    url = new URL(urlString);
  } catch (e) {
    return false;
  }
  return url.protocol === "https:";
};

export const validateURL = (str) => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  );
  return !!pattern.test(str);
};

export const getHelperText = (errors, fieldName, defaultText) => {
  let text = errors[fieldName] ? errors[fieldName] : defaultText;
  return String(text);
};

export const removeRecaptcha = () => {
  const script = document.getElementById(RECAPTCHA_PUBLIC_KEY);
  if (script) {
    script.remove();
  }

  const recaptchaElems = document.getElementsByClassName("grecaptcha-badge");
  if (recaptchaElems.length) {
    recaptchaElems[0].remove();
  }
};

export const loadScriptByURL = () => {
  const id = RECAPTCHA_PUBLIC_KEY;
  const url = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_PUBLIC_KEY}`;
  const callback = function () {
    // console.log("Script loaded!");
  };

  const isScriptExist = document.getElementById(id);

  if (!isScriptExist) {
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.src = url;
    script.id = id;
    script.onload = function () {
      callback();
    };
    document.body.appendChild(script);
  }

  if (isScriptExist && callback) callback();
};

export const GAEvent = (category, action, label) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
};

export const sortArrayByName = (array) =>
  array.length > 0
    ? array
        .slice()
        .sort((s1, s2) => (s1.name > s2.name ? 1 : s2.name > s1.name ? -1 : 0))
    : array;
