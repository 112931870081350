import React, { useReducer, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { useQuery } from "@apollo/client";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Pagination from "components/Pagination";
import Query from "components/Query";

import SolutionCard from "components/SolutionCard";
import { GET_SOLUTIONS_BY_FILTER } from "queries/solutions";
import { GET_SOLUTIONS_FILTERED_COUNT } from "queries/counts";

import filterState from "../../states/filterState";
import reducer from "./lib/reducer";
import initialState from "./lib/initialState";

const useStyles = makeStyles(() => ({
  mainBox: {
    minHeight: "1008px",
  },
  noResult: {
    minHeight: "300px",
  },
}));

const Solutions = () => {
  const limit = 9;

  const classes = useStyles({});
  const [state, dispatch] = useReducer(reducer, initialState);
  const filter = useRecoilValue(filterState);

  const { data = {}, loading } = useQuery(GET_SOLUTIONS_FILTERED_COUNT, {
    variables: { ...state },
  });
  const { solutionsByFilterCollection = { count: 0 } } = data;

  const handleOnChange = (event, value) => {
    dispatch({ type: "page", payload: value, limit: limit });
  };

  useEffect(() => {
    dispatch({ type: "filter", payload: filter, limit: limit });
  }, [filter]);

  if (loading) return null;

  return (
    <Box>
      {solutionsByFilterCollection.count > 0 ? (
        <>
          <Grid container spacing={3} className={classes.mainBox}>
            <Query
              query={GET_SOLUTIONS_BY_FILTER}
              start={state.start}
              limit={limit}
              {...state}
            >
              {({ data: { solutionsByFilter } }) =>
                solutionsByFilter.map((solution) => (
                  <Grid item xs={12} md={6} lg={4} key={solution.id}>
                    <SolutionCard
                      name={solution.name}
                      challenges={solution.challenges}
                      id={solution.id}
                      media={solution.media}
                      greenSolution={solution.green_solution}
                    />
                  </Grid>
                ))
              }
            </Query>
          </Grid>

          <Box display="flex" justifyContent="center" marginTop="4rem">
            <Pagination
              counter={solutionsByFilterCollection.count}
              limit={limit}
              page={state.page}
              handleOnChange={handleOnChange}
            />
          </Box>
        </>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          marginTop="8rem"
          className={classes.noResult}
        >
          <Typography variant="h6">
            No content match your search criteria
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default Solutions;
